
body,input,button,select,textarea,pre{font-family:"\5FAE\8F6F\96C5\9ED1",Helvetica,STHeiti,Droid Sans Fallback;
}
::-webkit-input-placeholder { /* WebKit browsers */ color: #CCCCCC;
}
:-moz-placeholder{ /* Mozilla Firefox 4 to 18 */ color: #CCCCCC;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */ color: #CCCCCC;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */ color: #CCCCCC;
}
@-webkit-keyframes dotrotate {
from { -webkit-transform: rotate(0deg); transform: rotate(0deg);
}
to { -webkit-transform: rotate(360deg); transform: rotate(360deg);
}
}
@keyframes dotrotate {
from { -webkit-transform: rotate(0deg); transform: rotate(0deg);
}
to { -webkit-transform: rotate(360deg); transform: rotate(360deg);
}
}
@-webkit-keyframes dotscale {
0%, 10% { width: 16px; height: 16px; margin-left: -8px;  margin-top: -4px;
}
50% { width: 8px; height: 8px; margin-left: -4px; margin-top: 0;
}
90%, 100% { width: 16px; height: 16px;  margin-left: -8px;  margin-top: -4px;
}
}
@keyframes dotscale {
0%, 10% { width: 16px; height: 16px; margin-left: -8px;  margin-top: -4px;
}
50% { width: 8px; height: 8px; margin-left: -4px; margin-top: 0;
}
90%, 100% { width: 16px; height: 16px;  margin-left: -8px;  margin-top: -4px;
}
}
.app-loader-main{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 100; background-color: rgba(255,255,255,0.5); text-align: center; font-size: 12px;
}
.app-loader-main .app-loader { width: 60px; height: 60px; position: absolute; z-index: 100; top: 50%; left: 50%; margin-top: -30px; margin-left: -30px;
}
.app-loader-main .app-loader .dot { position: absolute; top: 0; left: 0; width: 60px; height: 60px; -webkit-animation: 1.7s dotrotate cubic-bezier(0.775, 0.005, 0.310, 1.000) infinite; animation: 1.7s dotrotate cubic-bezier(0.775, 0.005, 0.310, 1.000) infinite;
}
.app-loader-main .app-loader .dot:nth-child(1) { -webkit-animation-delay: 0.2s; animation-delay: 0.2s;
}
.app-loader-main .app-loader .dot:nth-child(2) { -webkit-animation-delay: 0.35s; animation-delay: 0.35s;
}
.app-loader-main .app-loader .dot:nth-child(3) { -webkit-animation-delay: 0.45s; animation-delay: 0.45s;
}
.app-loader-main .app-loader .dot:nth-child(4) { -webkit-animation-delay: 0.55s; animation-delay: 0.55s;
}
.app-loader-main .app-loader .dot:after, .app-loader-main .app-loader .dot .first { content: ""; position: absolute; width: 8px; height: 8px; background: #02aff1; border-radius: 50%; left: 50%; margin-left: -4px;
}
.app-loader-main .app-loader .dot .first { background: #02aff1; margin-top: -4px; -webkit-animation: 1.7s dotscale cubic-bezier(0.775, 0.005, 0.310, 1.000) infinite; animation: 1.7s dotscale cubic-bezier(0.775, 0.005, 0.310, 1.000) infinite; -webkit-animation-delay: 0.2s; animation-delay: 0.2s;
}
.app-loader-main .app-message{ width: 300px; line-height: 30px; position: absolute; top: 50%; left: 50%; margin-top: 35px; margin-left: -150px;
}
.app-loader-main .app-message span{ display: inline-block; padding-right: 20px; padding-left: 20px; font-size: 12px;
}
.app-message-container{ position: absolute; z-index: 99; height: 32px; line-height: 32px; text-align: center; top: 50%; right: 0; left: 0; margin-top: -16px;
}
.app-message-container span{ display: inline-block; padding-right: 20px; padding-left: 20px; background-color: rgba(0,0,0,0.7); color: #FFF; font-size: 12px;
}
.view-app { position: relative; width: 100%; height: 100%; -webkit-perspective: 1200px; perspective: 1200px; -webkit-transform-style: preserve-3d; transform-style: preserve-3d; background-color: #F5F6FA;
}
.view-main { -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0);
}
.webuploader-container div, .webuploader-container input{ width: 100%!important; height: 100%!important;
}
.app-screen-header-user{ -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; margin: 0 50px;
}
.app-screen-header-user .user-head { width: 50px;
}
.app-screen-header-user .user-avatar { width: 30px; height: 30px; border-radius: 50%; margin: 10px auto; background-color: #EBEBEB; overflow: hidden;
}
.app-screen-header-user .user-avatar.avatar{ background-image: url(/static/images/icon-avatar.png); background-position: center; background-repeat: no-repeat; background-size: contain;
}
.app-screen-header-user .user-avatar .user-avatar-img{ width: 100%; vertical-align: inherit; margin-bottom: 10px;
}
.app-screen-header-user .user-name{ padding: 0 10px 0 0; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1;
}
.bold{ font-weight: bold;
}
.mb-10{ margin-bottom: 10px;
}
.mr-10{ margin-right: 10px;
}
.order-tag{ border: 1px solid #EB3C38; border-radius: 3px; padding: 2px 4px; color: #EB3C38; font-size: 12px; margin-right: 5px;
}
.img-auto{ width: 100%; height: 100%;
}
.text-bold{ font-weight: bold;
}

.error-container[data-v-f5e082ec]{ padding-top: 50px; padding-right: 30px; padding-left: 30px; text-align: center;
}
.error-message[data-v-f5e082ec]{ line-height: 35px; padding-top: 20px;
}

.app-screen[data-v-3258321a] { position: relative; width: 100%; height: 100%; -webkit-perspective: 1200px; perspective: 1200px; -webkit-transform-style: preserve-3d; transform-style: preserve-3d; background-color: #FFF;
}
.app-screen-main[data-v-3258321a] { -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0);
}
.app-screen-container[data-v-3258321a]{ padding-top: 30px; padding-right: 10px; padding-left: 10px; position: relative;
}
.app-screen-container .step-enroll[data-v-3258321a]{ position: relative; font-size: 16px; border-bottom: 1px solid #EBEBEB; padding-bottom: 15px; padding-top: 12px;
}
.app-screen-container .step-enroll .identifier[data-v-3258321a]{ position: absolute; top: 12px; left: 0; width: 50px; height: 25px; line-height: 25px; color: #CCC; text-align: center;
}
.app-screen-container .step-enroll .code[data-v-3258321a]{ padding-left: 0; text-align: center;
}
.app-screen-container .step-enroll .password[data-v-3258321a]{ padding-right: 10px; padding-left: 10px;
}
.app-screen-container .step-enroll input[data-v-3258321a]{ border: 0; background-color: transparent; width: 100%; height: 25px; line-height: 25px; padding-left: 50px; outline: none;
}
.app-screen-container .step-notes[data-v-3258321a]{ font-size: 12px; text-align: right; color: #B5B6CA; padding-top: 18px; padding-bottom: 18px; font-size: 14px;
}
.app-screen-container .step-tools[data-v-3258321a]{ margin-top: 20px; text-align: center; height: 22px; line-height: 22px;
}
.app-screen-container .step-container[data-v-3258321a]{ padding-top: 70px; text-align: center; font-size: 16px;
}
.app-screen-container .step-button[data-v-3258321a]{ display: block; width: 100%; height: 50px; line-height: 50px; text-align: center; background-color: #CCCCCC; color: #FFF; border-radius: 25px; border: 1px solid transparent;
}
.app-screen-container .step-button.active[data-v-3258321a]{ border-color: #FF6068; background: -webkit-gradient(linear, left top, left bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(#FE4B4B, #E81B16);
}
.app-screen-container .step-button.ignore[data-v-3258321a]{ margin-bottom: 8px; background-color: transparent; color: #FF6068;
}

.app-scroll-main[data-v-da3439ec]{ position: absolute; top: 0; right: 0; bottom: 0; left: 0;
}
.app-scroll-screen[data-v-da3439ec]{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; overflow: hidden;
}
.app-scroll-container[data-v-da3439ec]{ position: relative;
}

.app-screen-header[data-v-1793d720]{ position: absolute; top: 0; right: 0; left: 0; height: 40px; line-height: 40px; text-align: center; background-color: rgba(255,255,255,1); z-index: 9; font-size: 18px;
}
.app-screen-header .icon-be-back[data-v-1793d720]{ position: relative; overflow: hidden; padding-right: 5px; padding-left: 30px; color: #333; font-size: 14px;
}
.app-screen-header .right-button[data-v-1793d720]{ position: relative; overflow: hidden;
}
.app-screen-header .icon-be-back.mirror[data-v-1793d720]{ opacity: 0;
}
.app-screen-header .icon-be-back .back-button[data-v-1793d720]{ position: absolute; top: 50%; left: 12px; content: ''; width: 12px; height: 12px; margin-top: -6px; border: 1px solid transparent; border-bottom-color: #333; border-left-color: #333; -webkit-transform: rotate(45deg); transform: rotate(45deg);
}
.app-screen-header.honour[data-v-1793d720]{ background-color: #0e0d08;
}
.app-screen-header .t-flex[data-v-1793d720]{ height: 100%;
}
.app-screen-header.honour .icon-be-back[data-v-1793d720]{ color: #fff;
}
.app-screen-header.honour .content[data-v-1793d720]{ color: #fff1de;
}
.app-screen-header.honour .icon-be-back .back-button[data-v-1793d720]{ border-bottom-color: #fff; border-left-color: #fff;
}

.app-screen[data-v-7779e023] { position: relative; width: 100%; height: 100%; -webkit-perspective: 1200px; perspective: 1200px; -webkit-transform-style: preserve-3d; transform-style: preserve-3d; background-color: #FFF;
}
.app-screen-main[data-v-7779e023] { -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0);
}
.app-screen-container[data-v-7779e023]{ padding-top: 30px; padding-right: 10px; padding-left: 10px; position: relative;
}
.app-screen-container .step-enroll[data-v-7779e023]{ position: relative; font-size: 16px; border-bottom: 1px solid #EBEBEB; padding-bottom: 15px; padding-top: 12px;
}
.app-screen-container .step-enroll .identifier[data-v-7779e023]{ position: absolute; top: 12px; left: 0; width: 50px; height: 25px; line-height: 25px; color: #CCC; text-align: center;
}
.app-screen-container .step-enroll .username[data-v-7779e023]{ padding-right: 10px; padding-left: 10px;
}
.app-screen-container .step-enroll .code[data-v-7779e023]{ padding-left: 0; text-align: center;
}
.app-screen-container .step-enroll .password[data-v-7779e023]{ padding-right: 10px; padding-left: 10px;
}
.app-screen-container .step-enroll input[data-v-7779e023]{ border: 0; background-color: transparent; width: 100%; height: 25px; line-height: 25px; padding-left: 50px; outline: none;
}
.app-screen-container .step-notes[data-v-7779e023]{ font-size: 12px; text-align: right; color: #B5B6CA; padding-top: 18px; padding-bottom: 18px; font-size: 14px;
}
.app-screen-container .step-tools[data-v-7779e023]{ margin-top: 20px; text-align: center; height: 22px; line-height: 22px;
}
.app-screen-container .step-container[data-v-7779e023]{ padding-top: 70px; text-align: center; font-size: 16px;
}
.app-screen-container .step-button[data-v-7779e023]{ display: block; width: 100%; height: 50px; line-height: 50px; text-align: center; background-color: #CCCCCC; color: #FFF; border-radius: 25px; border: 1px solid transparent;
}
.app-screen-container .step-button.active[data-v-7779e023]{ border-color: #FF6068; background: -webkit-gradient(linear, left top, left bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(#FE4B4B, #E81B16);
}
.app-screen-container .step-button.ignore[data-v-7779e023]{ margin-bottom: 8px; background-color: transparent; color: #FF6068;
}

.staffMiniQr[data-v-7080382f]{position: relative; width: 100%; background-color: #2d3132; height: 100%;
}
.app-scroll-container[data-v-7080382f]{ height: 100% !important;
}
.staffMiniQr-box[data-v-7080382f]{ display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center; padding: 20px; height: 100%;
}
.mini-qr-box[data-v-7080382f]{ text-align: center; margin: 0 auto 50px; width: 80%; background-color: #fff; border-radius: 2px; padding: 20px;
}
.mini-qr-bd[data-v-7080382f]{ margin: 10px 0; min-height: 100px;
}
.mini-qr[data-v-7080382f]{ width: 90%; margin: 0 auto; display: block;
}
.mini-qr-remark[data-v-7080382f]{ margin-top: 20px;
}
.mini-qr-head[data-v-7080382f]{ text-align: left;
}
.staff-account[data-v-7080382f]{ font-weight: bold;
}
.step-button[data-v-7080382f]{ display: block; width: 80%; height: 50px; line-height: 50px; text-align: center; background-color: #CCCCCC; color: #FFF; border-radius: 25px; border: 1px solid transparent; margin: 0 auto;
}
.step-button.active[data-v-7080382f]{ border-color: #FF6068; background: -webkit-gradient(linear, left top, left bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(#FE4B4B, #E81B16);
}

.staff-center-header[data-v-442d5d75]{ height: 140px; background: -webkit-gradient(linear, left top, right bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(to bottom right, #FE4B4B, #E81B16); position: relative; overflow: hidden;
}
/*.staff-center-header::after{ content: ''; position: absolute; height: 20px; right: 0; bottom: -10px; left: 0; background-color: #FFF; border-radius: 10px; }*/
.staff-center-header-container[data-v-442d5d75]{ position: absolute; right: 10px; bottom: 30px; left: 10px; height: 60px; padding-left: 70px;
}
.staff-center-header-container .member-head[data-v-442d5d75]{ width: 60px; height: 60px; overflow: hidden; position: absolute; top: 0; left: 0; background-color: #FFF; border-radius: 50%;
}
.staff-center-header-container .member-head.avatar[data-v-442d5d75]{ background-image: url(/static/images/icon-avatar.png); background-position: center; background-repeat: no-repeat; background-size: contain;
}
.staff-center-header-container .member-head img[data-v-442d5d75]{ max-width: 100%; max-height: 100%; vertical-align: inherit;
}
.staff-center-header-container .member-name[data-v-442d5d75]{ font-size: 18px; color: #FFF; height: 30px; line-height: 30px;
}
.staff-center-header-container .member-group[data-v-442d5d75]{ display: inline-block; position: relative; padding-top: 4px; padding-bottom: 4px; padding-right: 6px; padding-left: 6px; background-color: #FFF; border-radius: 20px; color: #989FCA; font-size: 12px; margin-bottom: 4px;
}
.personal-center-line[data-v-442d5d75]{ display: block; overflow: hidden; height: 12px;
}
.personal-center-headline[data-v-442d5d75]{ padding-right: 10px; padding-left: 10px; height: 30px; line-height: 30px; color: rgba(95, 100, 115, 0.57); font-size: 12px; background-color: #FFF; position: relative;
}
.personal-center-headline[data-v-442d5d75]::after{ content: ''; position: absolute; right: 8px; bottom: 0; left: 8px; height: 1px; background-color: #F5F6FA;
}
.personal-center-menu[data-v-442d5d75]{ position: relative; font-size: 16px; padding-right: 10px; padding-left: 10px; background-color: #FFF;
}
.personal-center-menu .col-line[data-v-442d5d75]{ height: 50px; line-height: 50px; overflow: hidden; position: relative; padding-left: 38px; padding-right: 22px; background-size: 28px; background-position: 0 50%; background-repeat: no-repeat;
}
.personal-center-menu .col-line[data-v-442d5d75]::after{ content: ''; position: absolute; right: 0px; bottom: 0; left: 0px; height: 1px; overflow: hidden; background-color: #E5E5E5;
}
.personal-center-menu .col-line[data-v-442d5d75]:last-child::after{ display: none;
}
.personal-center-menu .col-line[data-v-442d5d75]::before{ content: ''; position: absolute; right: 5px; top: 50%; width: 8px; height: 8px; margin-top: -4px; border: 1px solid transparent; border-top-color: #999; border-right-color: #999; -webkit-transform: rotate(45deg); transform: rotate(45deg);
}
.personal-center-menu .col-line.icon-line-card[data-v-442d5d75]{ background-image: url(/static/images/icon-line-card.png);
}
.personal-center-menu .col-line.icon-line-collect[data-v-442d5d75]{ background-image: url(/static/images/icon-line-collect.png);
}
.personal-center-menu .col-line.icon-line-history[data-v-442d5d75]{ background-image: url(/static/images/icon-line-history.png);
}
.personal-center-menu .col-line.icon-line-member[data-v-442d5d75]{ background-image: url(/static/images/icon-line-member.png);
}
.personal-center-menu .col-line.icon-line-fans[data-v-442d5d75]{ background-image: url(/static/images/icon-line-fans.png);
}
.personal-center-menu .col-line.icon-line-order[data-v-442d5d75]{ background-image: url(/static/images/icon-line-order.png);
}
.personal-center-menu .col-line.icon-line-statistics[data-v-442d5d75]{ background-image: url(/static/images/icon-line-statistics.png);
}
.personal-center-menu .col-line.icon-line-customer[data-v-442d5d75]{ background-image: url(/static/images/icon-line-customer.png);
}
.personal-center-menu .col-line.icon-line-equipment[data-v-442d5d75]{ background-image: url(/static/images/icon-line-equipment.png);
}
.personal-center-menu .col-line.icon-line-ranking-list[data-v-442d5d75]{ background-image: url(/static/images/icon-line-ranking-list.png);
}
.personal-center-menu .col-line.icon-line-honour[data-v-442d5d75]{ background-image: url(/static/images/icon-line-honour.png);
}
.personal-center-menu .col-line.icon-line-order-group[data-v-442d5d75]{ background-image: url(/static/images/icon-line-order-group.png);
}
.personal-center-menu .col-line.icon-line-custom[data-v-442d5d75]{ background-image: url(/static/images/custom-list.png);
}
.personal-center-menu .col-line.icon-line-purchase[data-v-442d5d75]{ background-image: url(/static/images/purchase.png);
}
.personal-center-menu .col-line.icon-line-bargain[data-v-442d5d75]{ background-image: url(/static/images/bargain-list.png);
}
.personal-center-menu .col-line.icon-line-card[data-v-442d5d75]{ background-image: url(/static/images/card-list.png);
}
.personal-center-menu .col-line.icon-line-discount[data-v-442d5d75]{ background-image: url(/static/images/discount-list.png);
}
.personal-center-menu .col-line.icon-line-secondkill[data-v-442d5d75]{ background-image: url(/static/images/secondkill-list.png);
}
.personal-center-menu .col-line.icon-line-fight[data-v-442d5d75]{ background-image: url(/static/images/fight-list.png);
}
.personal-center-menu .col-line.icon-line-special[data-v-442d5d75]{ background-image: url(/static/images/special-list.png);
}
.personal-center-menu .col-line.icon-line-assistance[data-v-442d5d75]{ background-image: url(/static/images/assistance.png);
}
.personal-center-menu .col-line.icon-line-people[data-v-442d5d75]{ background-image: url(/static/images/people-list.png);
}
.personal-center-menu .col-line.icon-line-change-new[data-v-442d5d75]{ background-image: url(/static/images/change-new.png);
}
.personal-center-menu .col-line.icon-line-staff-logout[data-v-442d5d75]{ background-image: url(/static/images/icon-line-staff-logout.png); color: #EB3C38;
}
.personal-center-menu .col-line.icon-line-staff-logout[data-v-442d5d75]::before{ border-top-color: #EB3C38; border-right-color: #EB3C38;
}
.staffMiniQr-box[data-v-442d5d75]{ position: fixed; width: 100%; background-color: #2d3132; height: 100%; top: 0; left: 0; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center; padding: 20px;
}
.mini-qr-box[data-v-442d5d75]{ text-align: center; width: 90%; background-color: #fff; border-radius: 2px; padding: 20px; margin: 0 auto 50px;
}
.mini-qr-bd[data-v-442d5d75]{ margin: 10px 0; min-height: 100px;
}
.mini-qr[data-v-442d5d75]{ width: 100%; margin: 0 auto; display: block;
}
.mini-qr-remark[data-v-442d5d75]{ margin-top: 20px;
}
.mini-qr-head[data-v-442d5d75]{ text-align: left;
}
.staff-account[data-v-442d5d75]{ font-weight: bold;
}
.step-button[data-v-442d5d75]{ display: block; width: 80%; height: 50px; line-height: 50px; text-align: center; background-color: #CCCCCC; color: #FFF; border-radius: 25px; border: 1px solid transparent; margin: 0 auto;
}
.step-button.active[data-v-442d5d75]{ border-color: #FF6068; background: -webkit-gradient(linear, left top, left bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(#FE4B4B, #E81B16);
}

.app-screen-footer[data-v-188d4f69]{ position: absolute; right: 0; bottom: 0; left: 0; height: 50px; text-align: center; z-index: 9;
}
.app-screen-footer .footer-container[data-v-188d4f69]{ position: relative; padding-bottom: 23px; height: 50px;
}
.app-screen-footer .footer-container-icon[data-v-188d4f69]{ position: absolute; top: 2px; right: 0; bottom: 23px; left: 0; line-height: 1em;
}
.app-screen-footer .footer-container-icon img[data-v-188d4f69]{ max-width: 30px; max-height: 30px;
}
.app-screen-footer .footer-container-headline[data-v-188d4f69]{ position: absolute; right: 0; bottom: 0; left: 0; height: 23px; line-height: 23px;
}

.custommall-order-section[data-v-e85513b8]{ padding-top: 10px;
}
.custommall-order-body[data-v-e85513b8]{ position: relative; background-color: #FFF;
}
.custommall-order-headline[data-v-e85513b8]{ height: 40px; line-height: 40px; overflow: hidden; padding-right: 10px; padding-left: 10px; color: #555;
}
.custommall-order-headline .order-state[data-v-e85513b8]{ color: #EB3C38;
}
.custommall-order-context[data-v-e85513b8]{ position: relative; display: block; overflow: hidden;
}
.custommall-order-context .order-context[data-v-e85513b8]{ height: 100px; padding-left: 110px; padding-right: 10px; position: relative; overflow: hidden; margin-top: 10px;
}
.custommall-order-context .order-context[data-v-e85513b8]:first-child{ margin-top: 0px !important;
}
.custommall-order-context .order-context-image[data-v-e85513b8]{ position: absolute; top: 5px; bottom: 5px; left: 10px; width: 90px; background-color: #FAFAFA; border-radius: 6px; overflow: hidden; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;
}
.custommall-order-context .order-context-image img[data-v-e85513b8]{ max-width: 100%; max-height: 100%;
}
.custommall-order-context .order-context-body[data-v-e85513b8]{ line-height: 25px;
}
.custommall-order-context .order-context-body .order-headline[data-v-e85513b8]{ height: 50px; line-height: 25px;
}
.custommall-order-context .order-context-body .order-custom[data-v-e85513b8]{ opacity: 0.7;
}
.custommall-order-context .order-context-body .order-param[data-v-e85513b8]{ padding-left: 10px;
}
.custommall-order-context .order-context-body .order-price del[data-v-e85513b8]{ display: block; color: #CCC; text-align: right;
}
.custommall-order-context .order-context-body .order-quantity[data-v-e85513b8]{ height: 50px; line-height: 50px; text-align: right;
}
.custommall-order-context .order-footer[data-v-e85513b8]{ height: 50px; line-height: 50px; text-align: right; padding-right: 15px; padding-left: 15px;
}
.custommall-order-context .order-footer-price[data-v-e85513b8]{ font-weight: bold; font-size: 16px;
}
.custommall-order-context .order-footer-price-sub[data-v-e85513b8]{ font-size: 12px;
}
.custommall-order-context .order-price[data-v-e85513b8]{ color: #EB3C38; padding-left: 5px;
}
.custommall-order-context .order-state[data-v-e85513b8]{ text-align: right;
}
.custommall-order-context .order-staff[data-v-e85513b8]{ display: block; width: 100%; font-size: 12px; color: #999; padding-right: 15px; padding-left: 15px; padding-bottom: 15px; font-size: 12px; color: #999;
}
.custommall-order-context .order-button[data-v-e85513b8]{ text-align: right; padding-right: 15px; padding-left: 15px; padding-bottom: 15px; clear: both;
}
.custommall-order-context .order-button button[data-v-e85513b8]{ border-radius: 15px; border: 1px solid #C3C3C3; background-color: transparent; color: #C3C3C3; font-size: 12px; padding-right: 10px; padding-left: 10px; padding-top: 5px; padding-bottom: 5px; margin-left: 10px;
}
.custommall-order-context .order-button button.confirm[data-v-e85513b8]{ border: 1px solid #EB3C38; background-color: transparent; color: #EB3C38;
}
.custommall-order-extend[data-v-e85513b8]{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(255,255,255,1);
}
.custommall-order-extend.custommall-order-modal-from[data-v-e85513b8]{ background-color: rgba(0,0,0,0.5);
}
.custommall-order-modal-from .order-modal-from-close[data-v-e85513b8]{ width: 280px; margin: 0 auto; height: 40px;
}
.custommall-order-modal-from .order-modal-from-close .icon-close[data-v-e85513b8]{ display: block; width: 40px; height: 40px; line-height: 40px; float: right; background: url(/static/images/icon-close.png) center no-repeat; background-size: 20px;
}
.custommall-order-modal-from .order-modal-from[data-v-e85513b8]{ width: 280px; margin: 0 auto; background-color: #FFF; border-radius: 8px; padding-right: 20px; padding-left: 20px; padding-bottom: 20px;
}
.custommall-order-modal-from .order-modal-from-headline[data-v-e85513b8]{ height: 70px; line-height: 70px; font-size: 20px; font-weight: bold;
}
.custommall-order-modal-from .order-modal-from-select[data-v-e85513b8]{ border: 1px solid #EBEBEB; background: transparent; height: 40px; line-height: 40px; margin-bottom: 10px; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 0px 8px;
}
.custommall-order-modal-from .order-modal-from-input[data-v-e85513b8]{ border: 1px solid #EBEBEB; height: 40px; line-height: 40px; margin-bottom: 10px; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 0px 8px;
}
.custommall-order-modal-from .order-modal-from-textarea[data-v-e85513b8]{ border: 1px solid #EBEBEB; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 8px;
}
.custommall-order-modal-from .order-modal-from-button[data-v-e85513b8]{ height: 40px; line-height: 40px; margin-top: 20px; color: #FFF; border-radius: 20px; border-color: #FF6068; background: -webkit-gradient(linear, left top, left bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(#FE4B4B, #E81B16);
}
.custommall-order-header[data-v-e85513b8]{ height: 40px; line-height: 40px; text-align: center; background-color: #FFF; overflow-x: scroll; overflow-y: hidden; -webkit-overflow-scrolling: touch;
}
.custommall-order-header .order-state-item[data-v-e85513b8]{ position: relative; display: inline-block; width: auto; padding: 0 15px;
}
.custommall-order-header .order-state-item[data-v-e85513b8]::after{ content: ''; position: absolute; right: 0; bottom: 0; left: 0; height: 2px; overflow: hidden; background: transparent;
}
.custommall-order-header .order-state-item.active[data-v-e85513b8]{ color: #EB3C38;
}
.custommall-order-header .order-state-item.active[data-v-e85513b8]::after{ background: #EB3C38;
}
.order-modal-from-staff[data-v-e85513b8]{ color: #999; padding: 10px 0; margin: 0 10px; border: 1px solid #eaeaea; border-left: none; border-right: none;
}
.order-state-container[data-v-e85513b8]{ white-space: nowrap;
}
.custommall-order-header[data-v-e85513b8]::-webkit-scrollbar{ display: none;
}
.custommall-ranking-header[data-v-e85513b8]{ position: relative;
}
.custommall-ranking-search-content[data-v-e85513b8]{ height: 40px; line-height: 40px; position: relative; font-size: 14px; margin-top: 50px; background-color: #FFF;
}
.custommall-ranking-search-content[data-v-e85513b8]::after{ content: ''; position: absolute; right: 0; bottom: 0; left: 0; height: 1px; overflow: hidden; background: #EBEBEB;
}
.custommall-ranking-search-content .input-container[data-v-e85513b8]{ padding-right: 10px; padding-left: 10px; overflow: hidden; position: relative;
}
.custommall-ranking-search-content .input-container input[data-v-e85513b8]{ display: block; width: 100%; height: 40px; line-height: 1em; border: 0; background-color: transparent; outline: none;
}
.custommall-ranking-search-content .input-button[data-v-e85513b8]{ width: 60px; height: 40px; background: url(/static/images/icon-search.png) 50% 50% no-repeat; background-size: 20px;
}
.custommall-ranking-search-content .icon-sorting-button[data-v-e85513b8]{ width: 40px; height: 40px; background: url(/static/images/icon-sorting.png) 50% 50% no-repeat; background-size: 20px;
}
.custommall-ranking-sorting[data-v-e85513b8]{ position: absolute; right: 0px; top: 39px; width: 120px; background-color: #FFF; z-index: 99;
}
.custommall-ranking-sorting-item[data-v-e85513b8]{ height: 28px; line-height: 28px; padding-left: 15px; padding-right: 15px; overflow: hidden; border-left: solid 2px transparent; color: #999; position: relative;
}
.custommall-ranking-sorting-item.active[data-v-e85513b8]{ color: #EB3C38; border-left-color: #EB3C38;
}
.custommall-ranking-sorting-item[data-v-e85513b8]::after{ content: ''; position: absolute; right: 10px; bottom: 0; left: 10px; height: 1px; overflow: hidden; background: #EBEBEB;
}
.custommall-ranking-sorting-item[data-v-e85513b8]::before{ content: ''; display: inline-block; width: 0; height: 0; border: 4px solid #999; float: right; margin-top: 12px; border-color: #999 transparent transparent transparent;
}
.custommall-ranking-sorting-item.order-desc[data-v-e85513b8]::before{ border-color: transparent transparent #999 transparent; margin-top: 8px;
}
.order-custom-span[data-v-e85513b8]{ color: blue; border-bottom: 1px solid #0000FF;padding:0 4px;margin:0 4px;-webkit-box-sizing: border-box;box-sizing: border-box;
}
.ProCustomSDiv[data-v-e85513b8]{position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 9;
}
.ProCustomSDiv .ProCustomSDivYY[data-v-e85513b8]{ width: 100%; height: 100%; background-color: #333; opacity: .7;
}
.ProCustomSDiv-container[data-v-e85513b8]{ position: absolute; top: 0; left: 0; width: 100%; height: 100%;
}
.ProCustomSDiv .ProCustomSDivNav[data-v-e85513b8]{width: 70%; background-color: #fff; border-radius: 10px; -webkit-border-radius: 10px; -moz-border-radius: 10px; padding: 14px; -webkit-box-sizing: border-box; box-sizing: border-box;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs[data-v-e85513b8]{
    width: 100%; height: 120px; padding: 15px; padding-left: 100px; padding-right: 0; border-bottom: 1px solid #ccc; -webkit-box-sizing: border-box; box-sizing: border-box; position: relative;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div[data-v-e85513b8]:nth-child(1){position: absolute; width: 90px; height: 90px; top: 15px; left: 0; border: 1px solid #bbb; -webkit-box-sizing: border-box; box-sizing: border-box; border-radius: 8px; -webkit-border-radius: 8px; -moz-border-radius: 8px; overflow: hidden;
}
.flex-center[data-v-e85513b8]{ display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center;
}
.rotate-ring-content[data-v-e85513b8]{ -webkit-line-clamp: 2;
}
.mobileSelect { position: relative; z-index: 0; opacity: 0; visibility: hidden; -webkit-transition: opacity 0.4s, z-index 0.4s; transition: opacity 0.4s, z-index 0.4s; }
.mobileSelect * { margin: 0; padding: 0; -webkit-box-sizing: border-box; box-sizing: border-box; }
.mobileSelect .grayLayer { position: fixed; top: 0; left: 0; bottom: 0; right: 0; background: #eee; background: rgba(0, 0, 0, 0.5); z-index: 888; display: block; }
.mobileSelect .content { width: 100%; display: block; position: fixed; z-index: 889; color: black; -webkit-transition: all 0.4s; transition: all 0.4s; bottom: -350px; left: 0; background: white; }
.mobileSelect .content .fixWidth { width: 100%; position: relative; }
.mobileSelect .content .fixWidth:after { content: "."; display: block; height: 0; clear: both; visibility: hidden; }
.mobileSelect .content .btnBar { border-bottom: 1px solid #DCDCDC; font-size: 15px; height: 45px; position: relative; text-align: center; line-height: 45px; }
.mobileSelect .content .btnBar .cancel,
.mobileSelect .content .btnBar .ensure { height: 45px; width: 55px; cursor: pointer; position: absolute; top: 0; }
.mobileSelect .content .btnBar .cancel { left: 0; color: #666; font-size: 16px; }
.mobileSelect .content .btnBar .ensure { right: 0; color: #1e83d3; font-size: 16px; }
.mobileSelect .content .btnBar .title { font-size: 15px; padding: 0 15%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
.mobileSelect .content .panel:after { content: "."; display: block; height: 0; clear: both; visibility: hidden; }
.mobileSelect .content .panel .wheels { width: 100%; height: 200px; overflow: hidden; }
.mobileSelect .content .panel .wheel { position: relative; z-index: 0; float: left; width: 50%; height: 200px; overflow: hidden; -webkit-transition: width 0.3s ease; transition: width 0.3s ease; }
.mobileSelect .content .panel .wheel .selectContainer { display: block; text-align: center; -webkit-transition: -webkit-transform 0.18s ease-out; transition: -webkit-transform 0.18s ease-out; transition: transform 0.18s ease-out; transition: transform 0.18s ease-out, -webkit-transform 0.18s ease-out; }
.mobileSelect .content .panel .wheel .selectContainer li { font-size: 16px; display: block; height: 40px; line-height: 40px; cursor: pointer; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
.mobileSelect .content .panel .selectLine { height: 40px; width: 100%; position: absolute; top: 80px; pointer-events: none; -webkit-box-sizing: border-box; box-sizing: border-box; border-top: 1px solid #DCDCDC; border-bottom: 1px solid #DCDCDC; }
.mobileSelect .content .panel .shadowMask { position: absolute; top: 0; width: 100%; height: 200px; background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), color-stop(rgba(255, 255, 255, 0)), to(#ffffff)); background: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0), #ffffff); opacity: 0.9; pointer-events: none; }
.mobileSelect-show { opacity: 1; z-index: 10000; visibility: visible; }
.mobileSelect-show .content { bottom: 0; }

.custommall-order-section[data-v-48ea85e2]{ padding-top: 10px;
}
.custommall-order-body[data-v-48ea85e2]{ position: relative; background-color: #FFF;
}
.custommall-order-headline[data-v-48ea85e2]{ height: 40px; line-height: 40px; overflow: hidden; padding-right: 10px; padding-left: 10px; color: #555;
}
.custommall-order-headline .order-state[data-v-48ea85e2]{ color: #EB3C38;
}
.custommall-order-context[data-v-48ea85e2]{ position: relative; display: block; overflow: hidden;
}
.custommall-order-context .order-context[data-v-48ea85e2]{ height: 100px; padding-left: 110px; padding-right: 10px; position: relative; overflow: hidden; margin-top: 10px;
}
.custommall-order-context .order-context[data-v-48ea85e2]:first-child{ margin-top: 0px !important;
}
.custommall-order-context .order-context-image[data-v-48ea85e2]{ position: absolute; top: 5px; bottom: 5px; left: 10px; width: 90px; background-color: #FAFAFA; border-radius: 6px; overflow: hidden; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center;
}
.custommall-order-context .order-context-image img[data-v-48ea85e2]{ max-width: 100%; max-height: 100%;
}
.custommall-order-context .order-context-body[data-v-48ea85e2]{ line-height: 25px;
}
.custommall-order-context .order-context-body .order-headline[data-v-48ea85e2]{ height: 50px; line-height: 25px;
}
.custommall-order-context .order-context-body .order-custom[data-v-48ea85e2]{ opacity: 0.7;
}
.custommall-order-context .order-context-body .order-param[data-v-48ea85e2]{ padding-left: 10px;
}
.custommall-order-context .order-context-body .order-price del[data-v-48ea85e2]{ display: block; color: #CCC; text-align: right;
}
.custommall-order-context .order-context-body .order-quantity[data-v-48ea85e2]{ height: 50px; line-height: 50px; text-align: right;
}
.custommall-order-context .order-footer[data-v-48ea85e2]{ height: 50px; line-height: 50px; text-align: right; padding-right: 15px; padding-left: 15px;
}
.custommall-order-context .order-footer-price[data-v-48ea85e2]{ font-weight: bold; font-size: 16px;
}
.custommall-order-context .order-footer-price-sub[data-v-48ea85e2]{ font-size: 12px;
}
.custommall-order-context .order-price[data-v-48ea85e2]{ color: #EB3C38; padding-left: 5px;
}
.custommall-order-context .order-state[data-v-48ea85e2]{ text-align: right;
}
.custommall-order-context .order-staff[data-v-48ea85e2]{ display: block; width: 100%; font-size: 12px; color: #999; padding-right: 15px; padding-left: 15px; padding-bottom: 15px; font-size: 12px; color: #999;
}
.custommall-order-context .order-button[data-v-48ea85e2]{ text-align: right; padding-right: 15px; padding-left: 15px; padding-bottom: 15px; clear: both;
}
.custommall-order-context .order-button button[data-v-48ea85e2]{ border-radius: 15px; border: 1px solid #C3C3C3; background-color: transparent; color: #C3C3C3; font-size: 12px; padding-right: 10px; padding-left: 10px; padding-top: 5px; padding-bottom: 5px; margin-left: 10px;
}
.custommall-order-context .order-button button.confirm[data-v-48ea85e2]{ border: 1px solid #EB3C38; background-color: transparent; color: #EB3C38;
}
.custommall-order-extend[data-v-48ea85e2]{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(255,255,255,1);
}
.custommall-order-extend.custommall-order-modal-from[data-v-48ea85e2]{ background-color: rgba(0,0,0,0.5);
}
.custommall-order-modal-from .order-modal-from-close[data-v-48ea85e2]{ width: 280px; margin: 0 auto; height: 40px;
}
.custommall-order-modal-from .order-modal-from-close .icon-close[data-v-48ea85e2]{ display: block; width: 40px; height: 40px; line-height: 40px; float: right; background: url(/static/images/icon-close.png) center no-repeat; background-size: 20px;
}
.custommall-order-modal-from .order-modal-from[data-v-48ea85e2]{ width: 280px; margin: 0 auto; background-color: #FFF; border-radius: 8px; padding-right: 20px; padding-left: 20px; padding-bottom: 20px;
}
.custommall-order-modal-from .order-modal-from-headline[data-v-48ea85e2]{ height: 70px; line-height: 70px; font-size: 20px; font-weight: bold;
}
.custommall-order-modal-from .order-modal-from-select[data-v-48ea85e2]{ border: 1px solid #EBEBEB; background: transparent; height: 40px; line-height: 40px; margin-bottom: 10px; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 0px 8px;
}
.custommall-order-modal-from .order-modal-from-input[data-v-48ea85e2]{ border: 1px solid #EBEBEB; height: 40px; line-height: 40px; margin-bottom: 10px; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 0px 8px;
}
.custommall-order-modal-from .order-modal-from-textarea[data-v-48ea85e2]{ border: 1px solid #EBEBEB; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 8px;
}
.custommall-order-modal-from .order-modal-from-button[data-v-48ea85e2]{ height: 40px; line-height: 40px; margin-top: 20px; color: #FFF; border-radius: 20px; border-color: #FF6068; background: -webkit-gradient(linear, left top, left bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(#FE4B4B, #E81B16);
}
.custommall-order-header[data-v-48ea85e2]{ height: 40px; line-height: 40px; text-align: center; background-color: #FFF; overflow-x: scroll; overflow-y: hidden; -webkit-overflow-scrolling: touch;
}
.custommall-order-header .order-state-item[data-v-48ea85e2]{ position: relative; display: inline-block; width: auto; padding: 0 15px;
}
.custommall-order-header .order-state-item[data-v-48ea85e2]::after{ content: ''; position: absolute; right: 0; bottom: 0; left: 0; height: 2px; overflow: hidden; background: transparent;
}
.custommall-order-header .order-state-item.active[data-v-48ea85e2]{ color: #EB3C38;
}
.custommall-order-header .order-state-item.active[data-v-48ea85e2]::after{ background: #EB3C38;
}
.order-modal-from-staff[data-v-48ea85e2]{ color: #999; padding: 10px 0; margin: 0 10px; border: 1px solid #eaeaea; border-left: none; border-right: none;
}
.order-state-container[data-v-48ea85e2]{ white-space: nowrap;
}
.custommall-order-header[data-v-48ea85e2]::-webkit-scrollbar{ display: none;
}
.custommall-ranking-header[data-v-48ea85e2]{ position: relative;
}
.custommall-ranking-search-content[data-v-48ea85e2]{ height: 40px; line-height: 40px; position: relative; font-size: 14px; margin-top: 50px; background-color: #FFF;
}
.custommall-ranking-search-content[data-v-48ea85e2]::after{ content: ''; position: absolute; right: 0; bottom: 0; left: 0; height: 1px; overflow: hidden; background: #EBEBEB;
}
.custommall-ranking-search-content .input-container[data-v-48ea85e2]{ padding-right: 10px; padding-left: 10px; overflow: hidden; position: relative;
}
.custommall-ranking-search-content .input-container input[data-v-48ea85e2]{ display: block; width: 100%; height: 40px; line-height: 1em; border: 0; background-color: transparent; outline: none;
}
.icon-shop-button[data-v-48ea85e2]{ width: 40px; height: 40px; background: url(/static/images/icon-shop.png) 50% 50% no-repeat; background-size: 23px;
}
.icon-date-button[data-v-48ea85e2]{ width: 40px; height: 40px; background: url(/static/images/icon-date.png) 50% 50% no-repeat; background-size: 23px;
}
.custommall-ranking-search-content .input-button[data-v-48ea85e2]{ width: 60px; height: 40px; background: url(/static/images/icon-search.png) 50% 50% no-repeat; background-size: 20px;
}
.custommall-ranking-search-content .icon-sorting-button[data-v-48ea85e2]{ width: 40px; height: 40px; background: url(/static/images/icon-sorting.png) 50% 50% no-repeat; background-size: 20px;
}
.custommall-ranking-sorting[data-v-48ea85e2]{ position: absolute; right: 0px; top: 39px; width: 100px; background-color: #FFF; z-index: 99;
}
.custommall-ranking-sorting-item[data-v-48ea85e2]{text-overflow: ellipsis;white-space: nowrap; height: 28px; line-height: 28px; padding-left: 15px; padding-right: 15px; overflow: hidden; border-left: solid 2px transparent; color: #999; position: relative;
}
.custommall-ranking-sorting-item.active[data-v-48ea85e2]{ color: #EB3C38; border-left-color: #EB3C38;
}
.custommall-ranking-sorting-item[data-v-48ea85e2]::after{ content: ''; position: absolute; right: 10px; bottom: 0; left: 10px; height: 1px; overflow: hidden; background: #EBEBEB;
}
/*.custommall-ranking-sorting-item::before{ content: ''; display: inline-block; width: 0; height: 0; border: 4px solid #999; float: right; margin-top: 12px; border-color: #999 transparent transparent transparent; }*/
.custommall-ranking-sorting-item.order-desc[data-v-48ea85e2]::before{ border-color: transparent transparent #999 transparent; margin-top: 8px;
}
.order-custom-span[data-v-48ea85e2]{ color: blue; border-bottom: 1px solid #0000FF;padding:0 4px;margin:0 4px;-webkit-box-sizing: border-box;box-sizing: border-box;
}
.custommall-hr[data-v-48ea85e2]{width: 100%;height: 1px; background-color: #EBEBEB;margin-top: -1px;
}
.ProCustomSDiv[data-v-48ea85e2]{position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 9;
}
.ProCustomSDiv .ProCustomSDivYY[data-v-48ea85e2]{ width: 100%; height: 100%; background-color: #333; opacity: .7;
}
.ProCustomSDiv-container[data-v-48ea85e2]{ position: absolute; top: 0; left: 0; width: 100%; height: 100%;
}
.ProCustomSDiv .ProCustomSDivNav[data-v-48ea85e2]{width: 70%; background-color: #fff; border-radius: 10px; -webkit-border-radius: 10px; -moz-border-radius: 10px; padding: 14px; -webkit-box-sizing: border-box; box-sizing: border-box;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs[data-v-48ea85e2]{
    width: 100%; height: 120px; padding: 15px; padding-left: 100px; padding-right: 0; border-bottom: 1px solid #ccc; -webkit-box-sizing: border-box; box-sizing: border-box; position: relative;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div[data-v-48ea85e2]:nth-child(1){position: absolute; width: 90px; height: 90px; top: 15px; left: 0; border: 1px solid #bbb; -webkit-box-sizing: border-box; box-sizing: border-box; border-radius: 8px; -webkit-border-radius: 8px; -moz-border-radius: 8px; overflow: hidden;
}
.flex-center[data-v-48ea85e2]{ display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center;
}
.rotate-ring-content[data-v-48ea85e2]{ -webkit-line-clamp: 2;
}
/*  */
.custommall-ranking-section[data-v-48ea85e2]{ padding-top: 10px; padding-bottom: 10px; padding-right: 10px; padding-left: 10px; position: relative;
}
.custommall-ranking-section[data-v-48ea85e2]::after{ content: ''; position: absolute; right: 10px; bottom: 0; left: 10px; height: 1px; overflow: hidden; background: #EBEBEB;
}
.custommall-ranking-content[data-v-48ea85e2]{ position: relative; padding-right: 10px; height: 110px; position: relative; padding-left: 60px;
}
.custommall-ranking-content-head[data-v-48ea85e2]{ position: absolute; width: 50px; height: 50px; top: 5px; left: 0; border-radius: 50%; overflow: hidden; background-color: #EBEBEB;
}
.custommall-ranking-content-head.avatar[data-v-48ea85e2]{ background-image: url(/static/images/icon-avatar.png); background-position: center; background-repeat: no-repeat; background-size: contain;
}
.custommall-ranking-content-head img[data-v-48ea85e2]{ max-height: 100%; max-width: 100%;
}
.custommall-ranking-content-rankings[data-v-48ea85e2]{ position: absolute; width: 50px; height: 50px; line-height: 50px; font-weight: bold; color: #999; text-align: center; top: 65px; left: 0; border-radius: 50%; background-position: center; background-repeat: no-repeat; background-size: 28px;
}
.custommall-ranking-content-rankings.icon-rankings-first[data-v-48ea85e2]{ background-image: url(/static/images/icon-rankings-first.png); font-size: 0; line-height: 0;
}
.custommall-ranking-content-rankings.icon-rankings-second[data-v-48ea85e2]{ background-image: url(/static/images/icon-rankings-second.png); font-size: 0; line-height: 0;
}
.custommall-ranking-content-rankings.icon-rankings-third[data-v-48ea85e2]{ background-image: url(/static/images/icon-rankings-third.png); font-size: 0; line-height: 0;
}
.custommall-ranking-content-head.avatar[data-v-48ea85e2]{ background-image: url(/static/images/icon-avatar.png); background-position: center; background-repeat: no-repeat; background-size: contain;
}
.custommall-ranking-content-headline[data-v-48ea85e2]{ height: 35px; line-height: 35px;
}
.custommall-ranking-content-origin-mode[data-v-48ea85e2]{ font-size: 12px;
}
.custommall-ranking-content-line[data-v-48ea85e2]{ height: 1px; overflow: hidden; display: block; margin-top: 4px; margin-bottom: 4px; background: #EBEBEB;
}
.custommall-ranking-content-item[data-v-48ea85e2]{ height: 20px; line-height: 20px; font-size: 12px; color: #999;
}
.custommall-ranking-content-item .number[data-v-48ea85e2]{ font-size: 16px; color: #333;
}
.custommall-ranking-content-item .col-line[data-v-48ea85e2]{ width: 1px; margin-top: 10px; margin-bottom: 0; display: block; background: #EBEBEB;
}
.custommall-ranking-content-price[data-v-48ea85e2]{ height: 30px; line-height: 30px; font-size: 16px; text-align: center; color: #EB3C38;
}
.custommall-ranking-content-price .col-line[data-v-48ea85e2]{ width: 1px; margin-top: 0; margin-bottom: 10px; display: block; background: #EBEBEB;
}
.custommall-ranking-content-price sub[data-v-48ea85e2]{ bottom: 0; padding-left: 3px;
}
.custommall-ranking-content-body[data-v-48ea85e2]{ display: block; width: 100%; overflow: hidden;
}
.selectd-date[data-v-48ea85e2] { padding: 10px 5px 5px; color: #999; text-align: center; border-top: 1px solid #ebebeb; border-bottom: 1px solid #ebebeb;
}
.custommall-ranking-header .input-select[data-v-48ea85e2]{color: #999;float: left; margin-left: 10px
}
.custommall-ranking-header .select[data-v-48ea85e2] {width: 88px;float: left;text-indent: 7px
}
.custommall-ranking-down[data-v-48ea85e2]::before{ content: ''; display: inline-block; width: 0; height: 0; border: 5px solid #999; float: right; margin-top: 18px; border-color: #999 transparent transparent transparent;
}


.btn[data-v-3295af3e]{
    display: block;
    margin: 0 5px;
    margin-top: 5px;
    height: 35px;
    border: 1px solid #ebedf0;
}
.no-click[data-v-3295af3e]{
    opacity: 0.5;
}
.page-mid[data-v-3295af3e] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    line-height: 42px;
}
.btn-page[data-v-3295af3e] {
    font-size: 12px;
    line-height: 22px;
}
.btn-blue[data-v-3295af3e] {background: #2196F3;color: #fff
}
.btn-gray[data-v-3295af3e] {background: #DAE7EF;color: #000
}
.custommall-ranking-header[data-v-3295af3e]{ position: relative;
}
.custommall-ranking-search-content[data-v-3295af3e]{ height: 40px; line-height: 40px; position: relative; font-size: 14px; background-color: #FFF;
}
.custommall-ranking-search-content[data-v-3295af3e]::after{ content: ''; position: absolute; right: 0; bottom: 0; left: 0; height: 1px; overflow: hidden; background: #EBEBEB;
}
.custommall-ranking-search-content .input-container[data-v-3295af3e]{-webkit-box-flex: 1;-ms-flex: 1;flex: 1; padding-right: 10px; padding-left: 10px; overflow: hidden; position: relative;
}
.custommall-ranking-search-content .input-container input[data-v-3295af3e]{ display: block; width: 100%; height: 40px; line-height: 1em; border: 0; background-color: transparent; outline: none;
}
.custommall-ranking-search-content .input-button[data-v-3295af3e]{ width: 60px; height: 40px; background: url(/static/images/icon-search.png) 50% 50% no-repeat; background-size: 20px;
}
.custommall-ranking-sorting[data-v-3295af3e]{ position: absolute; right: 0px; top: 39px; width: 100px; background-color: #FFF; z-index: 99;
}
.custommall-ranking-sorting-item[data-v-3295af3e]{text-overflow: ellipsis;white-space: nowrap; height: 28px; line-height: 28px; padding-left: 15px; padding-right: 15px; overflow: hidden; border-left: solid 2px transparent; color: #999; position: relative;
}
.custommall-ranking-sorting-item.active[data-v-3295af3e]{ color: #EB3C38; border-left-color: #EB3C38;
}
.custommall-ranking-sorting-item[data-v-3295af3e]::after{ content: ''; position: absolute; right: 10px; bottom: 0; left: 10px; height: 1px; overflow: hidden; background: #EBEBEB;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div[data-v-3295af3e]:nth-child(1){position: absolute; width: 90px; height: 90px; top: 15px; left: 0; border: 1px solid #bbb; -webkit-box-sizing: border-box; box-sizing: border-box; border-radius: 8px; -webkit-border-radius: 8px; -moz-border-radius: 8px; overflow: hidden;
}
.custommall-ranking-down[data-v-3295af3e]::before{ content: ''; display: inline-block; width: 0; height: 0; border: 5px solid #999; float: right; margin-top: 18px; border-color: #999 transparent transparent transparent;
}
.choice-title[data-v-3295af3e] {margin: 0;height: 40px;line-height: 40px;font-size: 15px;text-align: center;background: #fff;
}
.custommall-goods-section[data-v-3295af3e] {padding-top: 10px;height: 110px;
}
.custommall-goods-section .custommall-goods-body[data-v-3295af3e] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    background: #fff;
    padding-top: 10px;
    padding-left: 10px;
}
.custommall-goods-section .goods-info[data-v-3295af3e] {
    padding: 10px 14px;
}
.custommall-goods-section .goods-info p[data-v-3295af3e]{
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.goods-radio[data-v-3295af3e] {
    width: 35px;
}
.goods-radio .active span[data-v-3295af3e]{
    display: block;
    width: 14px;
    height: 14px;
    background: #4A626E;
    margin: 2px;
}
.goods-radio .radio-box[data-v-3295af3e]{
    width: 20px;
    height: 20px;
    border: 1px solid #93A6BA;
    border-radius: 2px;
    margin-top: 30px;
}
.goods-context-img[data-v-3295af3e] {
    width: 80px;
    height: 80px;
    background-color: #FAFAFA;
    border-radius: 6px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.goods-context-img img[data-v-3295af3e] {
    max-width: 100%;
    max-height: 100%
}

.ProCustomSDiv[data-v-4b3625ae]{position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 9;
}
.ProCustomSDiv .ProCustomSDivYY[data-v-4b3625ae]{ width: 100%; height: 100%; background-color: #333; opacity: .7;
}
.ProCustomSDiv-container[data-v-4b3625ae]{ position: absolute; top: 0; left: 0; width: 100%; height: 100%;
}
.ProCustomSDiv .ProCustomSDivNav[data-v-4b3625ae]{width: 70%; background-color: #fff; border-radius: 10px; -webkit-border-radius: 10px; -moz-border-radius: 10px; padding: 14px; -webkit-box-sizing: border-box; box-sizing: border-box;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs[data-v-4b3625ae]{
    width: 100%; height: 120px; padding: 15px; padding-left: 100px; padding-right: 0; border-bottom: 1px solid #ccc; -webkit-box-sizing: border-box; box-sizing: border-box; position: relative;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div[data-v-4b3625ae]:nth-child(1){position: absolute; width: 90px; height: 90px; top: 15px; left: 0; border: 1px solid #bbb; -webkit-box-sizing: border-box; box-sizing: border-box; border-radius: 8px; -webkit-border-radius: 8px; -moz-border-radius: 8px; overflow: hidden;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div[data-v-4b3625ae]:nth-child(2){width: 100%; height: 100%;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div:nth-child(2) span[data-v-4b3625ae]{display: block; font-size: 13px; color: #333;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div img[data-v-4b3625ae]{position: absolute; left: 50%; -webkit-transform: translateX(-50%); transform: translateX(-50%); height: 100%; width: 100%;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs[data-v-4b3625ae]:nth-child(3) {padding-left: 72%;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs:nth-child(3) div[data-v-4b3625ae]:nth-child(1){width: 72%; text-align: center;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs:nth-child(3) div[data-v-4b3625ae]:last-of-type{text-align: center;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs:nth-child(3) .orderDataImgPlayer[data-v-4b3625ae]{ width: 30px; height: 30px; position: absolute; top: 20px; left: 8px;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs:nth-child(3) .orderDataImgPlayer img[data-v-4b3625ae]{ display: block; width: 100%; height: 100%;border: none;
}
.ProCustomSDiv .orderBigImage[data-v-4b3625ae]{position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: #000; z-index: 999;
}
.ProCustomSDiv .orderBigImage img[data-v-4b3625ae]{display: block; width: 60%; height: auto; margin:200px auto;
}
.custommall-order-header[data-v-4b3625ae]{ position: relative; overflow: hidden;
}
.custommall-order-header[data-v-4b3625ae]::after{ content: ''; position: absolute; top: 0; right: 0; left: 0; height: 145px; background: -webkit-gradient(linear, left top, right bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(to bottom right, #FE4B4B, #E81B16);
}
.custommall-order-header-container[data-v-4b3625ae]{ margin: 30px 15px; padding-left: 70px; position: relative; z-index: 9;
}
.custommall-order-header-container .member-head[data-v-4b3625ae]{ width: 60px; height: 60px; overflow: hidden; position: absolute; top: 0; left: 0; background-color: #FFF; border-radius: 50%;
}
.custommall-order-header-container .member-head.avatar[data-v-4b3625ae]{ background-image: url(/static/images/icon-avatar.png); background-position: center; background-repeat: no-repeat; background-size: contain;
}
.custommall-order-header-container .member-head img[data-v-4b3625ae]{ max-width: 100%; max-height: 100%; vertical-align: inherit;
}
.custommall-order-header-container .member-name[data-v-4b3625ae]{ font-size: 18px; color: #FFF; height: 30px; line-height: 30px;
}
.custommall-order-header-container .member-group[data-v-4b3625ae]{ display: inline-block; position: relative; padding-top: 4px; padding-bottom: 4px; padding-right: 6px; padding-left: 6px; background-color: #FFF; border-radius: 20px; color: #989FCA; font-size: 12px;
}
.custommall-order-header-address[data-v-4b3625ae]{ margin: 0 15px 15px; padding-bottom: 15px; overflow: hidden; display: block; border-radius: 6px; background-color: #FFF; position: relative; z-index: 9;
}
.custommall-order-header-address .order-address-icon[data-v-4b3625ae]{ width: 35px; height: 60px; display: block; background: url(/static/images/icon-position.png) center no-repeat; background-size: 25px;
}
.custommall-order-header-address .order-address-headline[data-v-4b3625ae]{ height: 50px; line-height: 50px; text-align: left; padding-left: 5px; padding-right: 20px; font-size: 16px;
}
.custommall-order-header-address .order-address-context[data-v-4b3625ae]{ padding-left: 5px; padding-right: 15px; font-size:12px; opacity: 0.7;
}
.order-detailed[data-v-4b3625ae]{ padding-top: 15px; padding-bottom: 15px; padding-right: 10px; padding-left: 10px;
}
.order-detailed-line[data-v-4b3625ae]{ height: 23px; line-height: 23px; font-size: 12px;
}
.custommall-order-section[data-v-4b3625ae]{ padding-top: 0;
}
.custommall-order-body[data-v-4b3625ae]{ position: relative; background-color: #FFF;
}
.custommall-order-context[data-v-4b3625ae]{ position: relative; display: block; overflow: hidden;
}
.custommall-order-context .order-context[data-v-4b3625ae]{ padding-left: 110px; padding-right: 10px; position: relative; overflow: hidden; margin-top: 10px;
}
.custommall-order-context .order-context-image[data-v-4b3625ae]{ position: absolute; top: 5px; bottom: 5px; left: 10px; width: 90px; background-color: #FAFAFA; border-radius: 6px; overflow: hidden; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;
}
.custommall-order-context .order-context-image img[data-v-4b3625ae]{ max-width: 100%; max-height: 100%;
}
.custommall-order-context .order-context-body[data-v-4b3625ae]{ line-height: 25px;
}
.custommall-order-context .order-context-body .order-headline[data-v-4b3625ae]{ height: 50px; line-height: 25px;
}
.custommall-order-context .order-context-body .order-custom[data-v-4b3625ae]{ opacity: 0.7;
}
.custommall-order-context .order-context-body .order-custom .order-custom-span[data-v-4b3625ae]{ color: blue; border-bottom: 1px solid #0000FF;padding:0 4px;margin:0 4px;-webkit-box-sizing: border-box;box-sizing: border-box;
}
.custommall-order-context .order-context-body .order-param[data-v-4b3625ae]{ padding-left: 10px;
}
.custommall-order-context .order-context-body .order-price del[data-v-4b3625ae]{ display: block; color: #CCC; text-align: right;
}
.custommall-order-context .order-context-body .order-quantity[data-v-4b3625ae]{ height: 50px; line-height: 50px; text-align: right;
}
.custommall-order-context .order-footer[data-v-4b3625ae]{ height: 50px; line-height: 50px; text-align: right; padding-right: 15px; padding-left: 15px;
}
.custommall-order-context .order-footer-price[data-v-4b3625ae]{ font-weight: bold; font-size: 16px;
}
.custommall-order-context .order-footer-price-sub[data-v-4b3625ae]{ font-size: 12px;
}
.custommall-order-context .order-button[data-v-4b3625ae]{ text-align: right; padding-right: 15px; padding-left: 15px; padding-bottom: 15px;
}
.custommall-order-context .order-button button[data-v-4b3625ae]{ border-radius: 15px; border: 1px solid #555; background-color: transparent; color: #555; font-size: 12px; padding-right: 10px; padding-left: 10px; padding-top: 5px; padding-bottom: 5px; margin-left: 10px;
}
.custommall-order-context .order-button button.confirm[data-v-4b3625ae]{ border: 1px solid #EB3C38; background-color: transparent; color: #EB3C38;
}
.custommall-order-detailed[data-v-4b3625ae]{ margin-top: 15px; background-color: #FFF; padding-right: 10px; padding-left: 10px; font-size: 12px; padding-top: 10px; padding-bottom: 10px;
}
.custommall-order-detailed .order-container-line[data-v-4b3625ae]{ height: 22px; line-height: 22px;
}
.custommall-order-extend[data-v-4b3625ae]{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(255,255,255,1);
}
.custommall-order-extend.custommall-order-modal-from[data-v-4b3625ae]{ background-color: rgba(0,0,0,0.5);
}
.custommall-order-modal-from .order-modal-from-close[data-v-4b3625ae]{ width: 280px; margin: 0 auto; height: 40px;
}
.custommall-order-modal-from .order-modal-from-close .icon-close[data-v-4b3625ae]{ display: block; width: 40px; height: 40px; line-height: 40px; float: right; background: url(/static/images/icon-close.png) center no-repeat; background-size: 20px;
}
.custommall-order-modal-from .order-modal-from[data-v-4b3625ae]{ width: 280px; margin: 0 auto; background-color: #FFF; border-radius: 8px; padding-right: 20px; padding-left: 20px; padding-bottom: 20px;
}
.custommall-order-modal-from .order-modal-from-headline[data-v-4b3625ae]{ height: 70px; line-height: 70px; font-size: 20px; font-weight: bold;
}
.custommall-order-modal-from .order-modal-from-textarea[data-v-4b3625ae]{ border: 1px solid #EBEBEB; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 8px;
}
.custommall-order-modal-from .order-modal-from-button[data-v-4b3625ae]{ height: 40px; line-height: 40px; margin-top: 20px; color: #FFF; border-radius: 20px; border-color: #FF6068; background: -webkit-gradient(linear, left top, left bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(#FE4B4B, #E81B16);
}
.custommall-order-footer[data-v-4b3625ae]{ display: block; text-align: left; padding-left: 10px; color: #333;
}
.custommall-order-footer .footer-content[data-v-4b3625ae]{ height: 50px; line-height: 50px; font-size: 16px;
}
.custommall-order-footer .order-price[data-v-4b3625ae]{ font-weight: bold; font-size: 16px; color: #E81B16;
}
.custommall-order-footer .order-price-sub[data-v-4b3625ae]{ font-size: 12px; margin-right: 4px;
}
.flex-center[data-v-4b3625ae]{ display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center;
}
.order-container-list[data-v-4b3625ae]{ padding-left: 20px; list-style: none;
}
.order-container-list-item[data-v-4b3625ae]{ position: relative; padding-bottom: 10px;
}
.order-container-list-item[data-v-4b3625ae]:before{ content: ""; position: absolute; left: -15px; top: 5px; width: 5px; height: 5px; border-radius: 50%; background-color: #e9e9e9; z-index: 2;
}
.order-container-list-item.active[data-v-4b3625ae]:before{ background-color: #E81B16;
}
.order-container-list-item[data-v-4b3625ae]:after{ content: ""; position: absolute; left: -13px; top: 5px; width: 1px; height: 100%; background-color: #e9e9e9; z-index: 1;
}
.order-container-list-item[data-v-4b3625ae]:last-child:after{ display: none;
}
.order-container-list-item[data-v-4b3625ae]:last-child{ padding-bottom: 0;
}
.order-status-txt[data-v-4b3625ae]{ color: #E81B16;
}
.order-delivery-form[data-v-4b3625ae]{ text-align: left;
}
.express-company[data-v-4b3625ae]{ color: #ccc;
}
.order-change-img[data-v-4b3625ae]{ display: inline-block; margin-right: 10px; margin-bottom: 10px; width: 50px; height: 50px;
}
.edit-icon-box[data-v-4b3625ae]{ float: right;
}
.edit-icon[data-v-4b3625ae]{ margin: 0 0 2px 4px; width: 12px; height: 12px;
}

.app-screen-footer[data-v-142dc9f6]{ position: absolute; right: 0; bottom: 0; left: 0; height: 50px; text-align: center; z-index: 9;
}
.app-screen-footer .footer-context[data-v-142dc9f6]{ padding-right: 10px;
}
.app-screen-footer .footer-container[data-v-142dc9f6]{ height: 50px; overflow: hidden;
}
.app-screen-footer .footer-button[data-v-142dc9f6]{ position: relative; height: 35px; margin-top: 7.5px; margin-right: 10px; font-size: 14px; border: 1px solid transparent; padding-right: 10px; padding-left: 10px; border-radius: 25px;
}
.app-screen-footer .footer-button-headline[data-v-142dc9f6]{ display: inline-block; height: 35px; line-height: 33px;
}
.app-screen-footer .footer-button.active[data-v-142dc9f6]{ border-color: #FF6068; color: #FFF; background: -webkit-gradient(linear, left top, right bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(to bottom right, #FE4B4B, #E81B16);
}
.app-screen-footer .footer-button.default[data-v-142dc9f6]{ border-color: transparent; color: #FFF; background: -webkit-gradient(linear, left top, right bottom, from(#CCCCCC), to(#DBDBDB)); background: linear-gradient(to bottom right, #CCCCCC, #DBDBDB);
}

.custommall-order-section[data-v-59bbfa0b]{ padding-top: 10px;
}
.custommall-order-body[data-v-59bbfa0b]{ position: relative; background-color: #FFF;
}
.custommall-order-headline[data-v-59bbfa0b]{ height: 40px; line-height: 40px; overflow: hidden; padding-right: 10px; padding-left: 10px; color: #555;
}
.custommall-order-headline .order-state[data-v-59bbfa0b]{ color: #EB3C38;
}
.custommall-order-context[data-v-59bbfa0b]{ position: relative; display: block; overflow: hidden;
}
.custommall-order-context .order-context[data-v-59bbfa0b]{ height: 100px; padding-left: 110px; padding-right: 10px; position: relative; overflow: hidden; margin-top: 10px;
}
.custommall-order-context .order-context[data-v-59bbfa0b]:first-child{ margin-top: 0px !important;
}
.custommall-order-context .order-context-image[data-v-59bbfa0b]{ position: absolute; top: 5px; bottom: 5px; left: 10px; width: 90px; background-color: #FAFAFA; border-radius: 6px; overflow: hidden; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;
}
.custommall-order-context .order-context-image img[data-v-59bbfa0b]{ max-width: 100%; max-height: 100%;
}
.custommall-order-context .order-context-body[data-v-59bbfa0b]{ line-height: 25px;
}
.custommall-order-context .order-context-body .order-headline[data-v-59bbfa0b]{ height: 50px; line-height: 25px;
}
.custommall-order-context .order-context-body .order-custom[data-v-59bbfa0b]{ opacity: 0.7;
}
.custommall-order-context .order-context-body .order-param[data-v-59bbfa0b]{ padding-left: 10px;
}
.custommall-order-context .order-context-body .order-price del[data-v-59bbfa0b]{ display: block; color: #CCC;
}
.custommall-order-context .order-context-body .order-quantity[data-v-59bbfa0b]{ height: 50px; line-height: 50px; text-align: right;
}
.custommall-order-context .order-footer[data-v-59bbfa0b]{ height: 50px; line-height: 50px; text-align: right; padding-right: 15px; padding-left: 15px;
}
.custommall-order-context .order-footer-price[data-v-59bbfa0b]{ font-weight: bold; font-size: 16px;
}
.custommall-order-context .order-footer-price-sub[data-v-59bbfa0b]{ font-size: 12px;
}
.custommall-order-context .order-price[data-v-59bbfa0b]{ color: #EB3C38; padding-left: 5px;
}
.custommall-order-context .order-state[data-v-59bbfa0b]{ text-align: right;
}
.custommall-order-context .order-staff[data-v-59bbfa0b]{ display: block; width: 100%; font-size: 12px; color: #999; padding-right: 15px; padding-left: 15px; padding-bottom: 15px; font-size: 12px; color: #999;
}
.custommall-order-context .order-button[data-v-59bbfa0b]{ text-align: right; padding-right: 15px; padding-left: 15px; padding-bottom: 15px; clear: both;
}
.custommall-order-context .order-button button[data-v-59bbfa0b]{ border-radius: 15px; border: 1px solid #C3C3C3; background-color: transparent; color: #C3C3C3; font-size: 12px; padding-right: 10px; padding-left: 10px; padding-top: 5px; padding-bottom: 5px; margin-left: 10px;
}
.custommall-order-context .order-button button.confirm[data-v-59bbfa0b]{ border: 1px solid #EB3C38; background-color: transparent; color: #EB3C38;
}
.custommall-order-extend[data-v-59bbfa0b]{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(255,255,255,1);
}
.custommall-order-extend.custommall-order-modal-from[data-v-59bbfa0b]{ background-color: rgba(0,0,0,0.5);
}
.custommall-order-modal-from .order-modal-from-close[data-v-59bbfa0b]{ width: 280px; margin: 0 auto; height: 40px;
}
.custommall-order-modal-from .order-modal-from-close .icon-close[data-v-59bbfa0b]{ display: block; width: 40px; height: 40px; line-height: 40px; float: right; background: url(/static/images/icon-close.png) center no-repeat; background-size: 20px;
}
.custommall-order-modal-from .order-modal-from[data-v-59bbfa0b]{ width: 280px; margin: 0 auto; background-color: #FFF; border-radius: 8px; padding-right: 20px; padding-left: 20px; padding-bottom: 20px;
}
.custommall-order-modal-from .order-modal-from-headline[data-v-59bbfa0b]{ height: 70px; line-height: 70px; font-size: 20px; font-weight: bold;
}
.custommall-order-modal-from .order-modal-from-select[data-v-59bbfa0b]{ border: 1px solid #EBEBEB; background: transparent; height: 40px; line-height: 40px; margin-bottom: 10px; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 0px 8px;
}
.custommall-order-modal-from .order-modal-from-input[data-v-59bbfa0b]{ border: 1px solid #EBEBEB; height: 40px; line-height: 40px; margin-bottom: 10px; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 0px 8px;
}
.custommall-order-modal-from .order-modal-from-textarea[data-v-59bbfa0b]{ border: 1px solid #EBEBEB; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 8px;
}
.custommall-order-modal-from .order-modal-from-button[data-v-59bbfa0b]{ height: 40px; line-height: 40px; margin-top: 20px; color: #FFF; border-radius: 20px; border-color: #FF6068; background: -webkit-gradient(linear, left top, left bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(#FE4B4B, #E81B16);
}
.custommall-order-header[data-v-59bbfa0b]{ height: 40px; line-height: 40px; text-align: center; background-color: #FFF; overflow-x: scroll; overflow-y: hidden; -webkit-overflow-scrolling: touch;
}
.custommall-order-header[data-v-59bbfa0b]::-webkit-scrollbar{ display: none;
}
.custommall-order-header .order-state-item[data-v-59bbfa0b]{ position: relative; display: inline-block; width: auto; padding: 0 15px;
}
.custommall-order-header .order-state-item[data-v-59bbfa0b]::after{ content: ''; position: absolute; right: 0; bottom: 0; left: 0; height: 2px; overflow: hidden; background: transparent;
}
.custommall-order-header .order-state-item.active[data-v-59bbfa0b]{ color: #EB3C38;
}
.custommall-order-header .order-state-item.active[data-v-59bbfa0b]::after{ background: #EB3C38;
}
.order-modal-from-staff[data-v-59bbfa0b] { color: #999; padding: 10px 0; margin: 0 10px; border: 1px solid #eaeaea; border-left: none; border-right: none;
}
.custommall-ranking-header[data-v-59bbfa0b]{ position: relative; padding-top: 50px;
}
.custommall-ranking-search-content[data-v-59bbfa0b]{ height: 40px; line-height: 40px; position: relative; font-size: 14px; background-color: #FFF;
}
.custommall-ranking-search-content[data-v-59bbfa0b]::after{ content: ''; position: absolute; right: 0; bottom: 0; left: 0; height: 1px; overflow: hidden; background: #EBEBEB;
}
.custommall-ranking-search-content .input-container[data-v-59bbfa0b]{ padding-right: 10px; padding-left: 10px; overflow: hidden; position: relative;
}
.custommall-ranking-search-content .input-container input[data-v-59bbfa0b]{ display: block; width: 100%; height: 40px; line-height: 1em; border: 0; background-color: transparent; outline: none;
}
.custommall-ranking-search-content .input-button[data-v-59bbfa0b]{ width: 60px; height: 40px; background: url(/static/images/icon-search.png) 50% 50% no-repeat; background-size: 20px;
}
.custommall-ranking-search-content .icon-sorting-button[data-v-59bbfa0b]{ width: 40px; height: 40px; background: url(/static/images/icon-sorting.png) 50% 50% no-repeat; background-size: 20px;
}
.icon-shop-button[data-v-59bbfa0b]{ width: 40px; height: 40px; background: url(/static/images/icon-shop.png) 50% 50% no-repeat; background-size: 23px;
}
.icon-date-button[data-v-59bbfa0b]{ width: 40px; height: 40px; background: url(/static/images/icon-date.png) 50% 50% no-repeat; background-size: 23px;
}
.custommall-ranking-sorting[data-v-59bbfa0b]{ position: absolute; right: 0px; width: 120px; background-color: #FFF; z-index: 99;
}
.custommall-ranking-sorting-item[data-v-59bbfa0b]{ height: 28px; line-height: 28px; padding-left: 15px; padding-right: 15px; overflow: hidden; border-left: solid 2px transparent; color: #999; position: relative;
}
.custommall-ranking-sorting-item.active[data-v-59bbfa0b]{ color: #EB3C38; border-left-color: #EB3C38;
}
.custommall-ranking-sorting-item[data-v-59bbfa0b]::after{ content: ''; position: absolute; right: 10px; bottom: 0; left: 10px; height: 1px; overflow: hidden; background: #EBEBEB;
}
.custommall-ranking-sorting-item[data-v-59bbfa0b]::before{ content: ''; display: inline-block; width: 0; height: 0; border: 4px solid #999; float: right; margin-top: 12px; border-color: #999 transparent transparent transparent;
}
.custommall-ranking-sorting-item.order-desc[data-v-59bbfa0b]::before{ border-color: transparent transparent #999 transparent; margin-top: 8px;
}
.remove-sorting[data-v-59bbfa0b]:before{ display: none;
}
.text-red[data-v-59bbfa0b]{ color: #EB3C38;
}
.custommall-ranking-header-people[data-v-59bbfa0b]{ width: 100%; height: 40px; line-height: 40px; background-color: #fff; padding: 0 10px;
}
.equipment-modal-from-checkbox[data-v-59bbfa0b]{ display: block; text-align: left; font-weight: normal; font-size: 12px;
}
.equipment-modal-from-checkbox input[data-v-59bbfa0b]{ top: 2px; position: relative; margin-right: 8px;
}
.order-state-container[data-v-59bbfa0b]{ white-space: nowrap;
}
.text-danger[data-v-59bbfa0b]{ color: #EB3C38;
}
.top-tips[data-v-59bbfa0b] { padding: 5px 10px; background-color: #fef8e8; color: #eab033; font-size: 12px;
}
.top-tips-icon[data-v-59bbfa0b] { margin-right: 5px; color: #fea003;
}
.selectd-date[data-v-59bbfa0b] { padding: 10px 5px 0 5px; color: #999;
}
.shop-name[data-v-59bbfa0b]{ width: 100px;
}

.ProCustomSDiv[data-v-7db5d97e]{position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 9;
}
.ProCustomSDiv .ProCustomSDivYY[data-v-7db5d97e]{ width: 100%; height: 100%; background-color: #333; opacity: .7
}
.ProCustomSDiv-container[data-v-7db5d97e]{ position: absolute; top: 0; left: 0; width: 100%; height: 100%;
}
.ProCustomSDiv .ProCustomSDivNav[data-v-7db5d97e]{width: 70%; background-color: #fff; position: absolute; left: 50%; top: 180px; -webkit-transform: translateX(-50%); transform: translateX(-50%); border-radius: 10px; -webkit-border-radius: 10px; -moz-border-radius: 10px; padding: 14px; -webkit-box-sizing: border-box; box-sizing: border-box;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs[data-v-7db5d97e]{
    width: 100%; height: 120px; padding: 15px; padding-left: 100px; padding-right: 0; border-bottom: 1px solid #ccc; -webkit-box-sizing: border-box; box-sizing: border-box; position: relative;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div[data-v-7db5d97e]:nth-child(1){position: absolute; width: 90px; height: 90px; top: 15px; left: 0; border: 1px solid #bbb; -webkit-box-sizing: border-box; box-sizing: border-box; border-radius: 8px; -webkit-border-radius: 8px; -moz-border-radius: 8px; overflow: hidden;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div[data-v-7db5d97e]:nth-child(2){width: 100%; height: 100%;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div:nth-child(2) span[data-v-7db5d97e]{display: block; font-size: 13px; color: #333;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div img[data-v-7db5d97e]{position: absolute; left: 50%; -webkit-transform: translateX(-50%); transform: translateX(-50%); height: 100%;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs[data-v-7db5d97e]:nth-child(3) {padding-left: 72%;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs:nth-child(3) div[data-v-7db5d97e]:nth-child(1){width: 72%; text-align: center;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs:nth-child(3) div[data-v-7db5d97e]:last-of-type{text-align: center;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs:nth-child(3) .orderDataImgPlayer[data-v-7db5d97e]{ width: 30px; height: 30px; position: absolute; top: 20px; left: 8px;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs:nth-child(3) .orderDataImgPlayer img[data-v-7db5d97e]{ display: block; width: 100%; height: 100%;border: none;
}
.ProCustomSDiv .orderBigImage[data-v-7db5d97e]{position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: #000; z-index: 999;
}
.ProCustomSDiv .orderBigImage img[data-v-7db5d97e]{display: block; width: 60%; height: auto; margin:200px auto;
}
.custommall-order-header[data-v-7db5d97e]{ position: relative; overflow: hidden;
}
.custommall-order-header[data-v-7db5d97e]::after{ content: ''; position: absolute; top: 0; right: 0; left: 0; height: 70%; background: -webkit-gradient(linear, left top, right bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(to bottom right, #FE4B4B, #E81B16);
}
.custommall-order-header-container[data-v-7db5d97e]{ margin: 30px 15px; padding-left: 70px; position: relative; z-index: 9;
}
.custommall-order-header-container .member-head[data-v-7db5d97e]{ width: 60px; height: 60px; overflow: hidden; position: absolute; top: 0; left: 0; background-color: #FFF; border-radius: 50%;
}
.custommall-order-header-container .member-head.avatar[data-v-7db5d97e]{ background-image: url(/static/images/icon-avatar.png); background-position: center; background-repeat: no-repeat; background-size: contain;
}
.custommall-order-header-container .member-head img[data-v-7db5d97e]{ max-width: 100%; max-height: 100%; vertical-align: inherit;
}
.custommall-order-header-container .member-name[data-v-7db5d97e]{ font-size: 18px; color: #FFF; height: 30px; line-height: 30px;
}
.custommall-order-header-container .member-group[data-v-7db5d97e]{ display: inline-block; position: relative; padding-top: 4px; padding-bottom: 4px; padding-right: 6px; padding-left: 6px; background-color: #FFF; border-radius: 20px; color: #989FCA; font-size: 12px; margin-bottom: 5px;
}
.custommall-order-header-address[data-v-7db5d97e]{ margin: 0 15px 15px; padding-bottom: 15px; overflow: hidden; display: block; border-radius: 6px; background-color: #FFF; position: relative; z-index: 9;
}
.custommall-order-header-address .order-address-icon[data-v-7db5d97e]{ width: 35px; height: 60px; display: block; background: url(/static/images/icon-position.png) center no-repeat; background-size: 25px;
}
.custommall-order-header-address .order-address-headline[data-v-7db5d97e]{ height: 50px; line-height: 50px; text-align: left; padding-left: 5px; padding-right: 20px; font-size: 16px;
}
.custommall-order-header-address .order-address-context[data-v-7db5d97e]{ padding-left: 5px; padding-right: 15px; font-size:12px; opacity: 0.7;
}
.order-detailed[data-v-7db5d97e]{ padding-top: 15px; padding-bottom: 15px; padding-right: 10px; padding-left: 10px;
}
.order-detailed-line[data-v-7db5d97e]{ height: 23px; line-height: 23px; font-size: 12px;
}
.custommall-order-section[data-v-7db5d97e]{ padding-top: 0;
}
.custommall-order-body[data-v-7db5d97e]{ position: relative; background-color: #FFF;
}
.custommall-order-context[data-v-7db5d97e]{ position: relative; display: block; overflow: hidden;
}
.custommall-order-context .order-context[data-v-7db5d97e]{ height: 100px; padding-left: 110px; padding-right: 10px; position: relative; overflow: hidden; margin-top: 10px;
}
.custommall-order-context .order-context-image[data-v-7db5d97e]{ position: absolute; top: 5px; bottom: 5px; left: 10px; width: 90px; background-color: #FAFAFA; border-radius: 6px; overflow: hidden; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;
}
.custommall-order-context .order-context-image img[data-v-7db5d97e]{ max-width: 100%; max-height: 100%;
}
.custommall-order-context .order-context-body[data-v-7db5d97e]{ line-height: 25px;
}
.custommall-order-context .order-context-body .order-headline[data-v-7db5d97e]{ height: 50px; line-height: 25px;
}
.custommall-order-context .order-context-body .order-custom[data-v-7db5d97e]{ opacity: 0.7;
}
.custommall-order-context .order-context-body .order-custom .order-custom-span[data-v-7db5d97e]{ color: blue; border-bottom: 1px solid #0000FF;padding:0 4px;margin:0 4px;-webkit-box-sizing: border-box;box-sizing: border-box;
}
.custommall-order-context .order-context-body .order-param[data-v-7db5d97e]{ padding-left: 10px;
}
.custommall-order-context .order-context-body .order-price del[data-v-7db5d97e]{ display: block; color: #CCC;
}
.custommall-order-context .order-context-body .order-quantity[data-v-7db5d97e]{ height: 50px; line-height: 50px; text-align: right;
}
.custommall-order-context .order-footer[data-v-7db5d97e]{ height: 50px; line-height: 50px; text-align: right; padding-right: 15px; padding-left: 15px;
}
.custommall-order-context .order-footer-price[data-v-7db5d97e]{ font-weight: bold; font-size: 16px;
}
.custommall-order-context .order-footer-price-sub[data-v-7db5d97e]{ font-size: 12px;
}
.custommall-order-context .order-button[data-v-7db5d97e]{ text-align: right; padding-right: 15px; padding-left: 15px; padding-bottom: 15px;
}
.custommall-order-context .order-button button[data-v-7db5d97e]{ border-radius: 15px; border: 1px solid #555; background-color: transparent; color: #555; font-size: 12px; padding-right: 10px; padding-left: 10px; padding-top: 5px; padding-bottom: 5px; margin-left: 10px;
}
.custommall-order-context .order-button button.confirm[data-v-7db5d97e]{ border: 1px solid #EB3C38; background-color: transparent; color: #EB3C38;
}
.custommall-order-detailed[data-v-7db5d97e]{ margin-top: 15px; background-color: #FFF; padding-right: 10px; padding-left: 10px; font-size: 12px; padding-top: 10px; padding-bottom: 10px;
}
.custommall-order-detailed .order-container-line[data-v-7db5d97e]{ height: 22px; line-height: 22px;
}
.custommall-order-extend[data-v-7db5d97e]{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(255,255,255,1);
}
.custommall-order-extend.custommall-order-modal-from[data-v-7db5d97e]{ background-color: rgba(0,0,0,0.5);
}
.custommall-order-modal-from .order-modal-from-close[data-v-7db5d97e]{ width: 280px; margin: 0 auto; height: 40px;
}
.custommall-order-modal-from .order-modal-from-close .icon-close[data-v-7db5d97e]{ display: block; width: 40px; height: 40px; line-height: 40px; float: right; background: url(/static/images/icon-close.png) center no-repeat; background-size: 20px;
}
.custommall-order-modal-from .order-modal-from[data-v-7db5d97e]{ width: 280px; margin: 0 auto; background-color: #FFF; border-radius: 8px; padding-right: 20px; padding-left: 20px; padding-bottom: 20px;
}
.custommall-order-modal-from .order-modal-from-headline[data-v-7db5d97e]{ height: 70px; line-height: 70px; font-size: 20px; font-weight: bold;
}
.custommall-order-modal-from .order-modal-from-textarea[data-v-7db5d97e]{ border: 1px solid #EBEBEB; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 8px;
}
.custommall-order-modal-from .order-modal-from-button[data-v-7db5d97e]{ height: 40px; line-height: 40px; margin-top: 20px; color: #FFF; border-radius: 20px; border-color: #FF6068; background: -webkit-gradient(linear, left top, left bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(#FE4B4B, #E81B16);
}
.custommall-order-footer[data-v-7db5d97e]{ display: block; text-align: left; padding-left: 10px; color: #333;
}
.custommall-order-footer .footer-content[data-v-7db5d97e]{ height: 50px; line-height: 50px; font-size: 16px;
}
.custommall-order-footer .order-price[data-v-7db5d97e]{ font-weight: bold; font-size: 16px; color: #E81B16;
}
.custommall-order-footer .order-price-sub[data-v-7db5d97e]{ font-size: 12px; margin-right: 4px;
}
.flex-center[data-v-7db5d97e]{ display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center;
}
.text-xs[data-v-7db5d97e]{ font-size: 12px;
}
.express-detail[data-v-7db5d97e]{ height: 400px; overflow-y: auto; -webkit-overflow-scrolling: touch;
}

.custommall-people-container[data-v-2d8ed2cc]{ display: block;
}
.custommall-people-search-content[data-v-2d8ed2cc]{ height: 40px; line-height: 40px; position: relative; font-size: 14px; background-color: #FFF;
}
.custommall-people-search-content[data-v-2d8ed2cc]::after{ content: ''; position: absolute; right: 0; bottom: 0; left: 0; height: 1px; overflow: hidden; background: #EBEBEB;
}
.custommall-people-search-content .input-container[data-v-2d8ed2cc]{ padding-right: 10px; padding-left: 10px; overflow: hidden; position: relative;
}
.custommall-people-search-content .input-container input[data-v-2d8ed2cc]{ display: block; width: 100%; height: 40px; line-height: 1em; border: 0; background-color: transparent; outline: none;
}
.custommall-people-search-content .input-button[data-v-2d8ed2cc]{ width: 60px; height: 40px; background: url(/static/images/icon-search.png) 50% 50% no-repeat; background-size: 20px;
}
.custommall-people-section[data-v-2d8ed2cc]{ padding-top: 10px; padding-bottom: 10px; padding-right: 10px; padding-left: 10px; position: relative;
}
.custommall-people-section[data-v-2d8ed2cc]::after{ content: ''; position: absolute; right: 10px; bottom: 0; left: 10px; height: 1px; overflow: hidden; background: #EBEBEB;
}
.custommall-people-content[data-v-2d8ed2cc]{ position: relative; padding-right: 10px; height: 90px; position: relative; padding-left: 80px;
}
.custommall-people-content-head[data-v-2d8ed2cc]{ position: absolute; width: 70px; height: 70px; top: 10px; left: 0; border-radius: 50%; overflow: hidden; background-color: #EBEBEB;
}
.custommall-people-content-head.avatar[data-v-2d8ed2cc]{ background-image: url(/static/images/icon-avatar.png); background-position: center; background-repeat: no-repeat; background-size: contain;
}
.custommall-people-content-head img[data-v-2d8ed2cc]{ max-height: 100%; max-width: 100%;
}
.custommall-people-content-headline[data-v-2d8ed2cc]{ height: 35px; line-height: 35px;
}
.custommall-people-content-origin-mode[data-v-2d8ed2cc]{ font-size: 12px;
}
.custommall-people-content-item[data-v-2d8ed2cc]{ font-size: 12px;
}
.custommall-people-content-body[data-v-2d8ed2cc]{ display: block; width: 100%; overflow: hidden;
}
.top-tips[data-v-2d8ed2cc] { margin-top: 50px; padding: 5px 10px; background-color: #fef8e8; color: #eab033; font-size: 12px;
}
.top-tips-icon[data-v-2d8ed2cc] { margin-right: 5px; color: #fea003;
}

.custommall-order-section[data-v-565398b4]{ padding-top: 10px;
}
.custommall-order-body[data-v-565398b4]{ position: relative; background-color: #FFF;
}
.custommall-order-headline[data-v-565398b4]{ height: 40px; line-height: 40px; overflow: hidden; padding-right: 10px; padding-left: 10px; color: #555;
}
.custommall-order-headline .order-state[data-v-565398b4]{ color: #EB3C38;
}
.custommall-order-context[data-v-565398b4]{ position: relative; display: block; overflow: hidden;
}
.custommall-order-context .order-context[data-v-565398b4]{ height: 100px; padding-left: 110px; padding-right: 10px; position: relative; overflow: hidden; margin-top: 10px;
}
.custommall-order-context .order-context[data-v-565398b4]:first-child{ margin-top: 0px !important;
}
.custommall-order-context .order-context-image[data-v-565398b4]{ position: absolute; top: 5px; bottom: 5px; left: 10px; width: 90px; background-color: #FAFAFA; border-radius: 6px; overflow: hidden; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center;
}
.custommall-order-context .order-context-image img[data-v-565398b4]{ max-width: 100%; max-height: 100%;
}
.custommall-order-context .order-context-body[data-v-565398b4]{ line-height: 25px;
}
.custommall-order-context .order-context-body .order-headline[data-v-565398b4]{ height: 50px; line-height: 25px;
}
.custommall-order-context .order-context-body .order-custom[data-v-565398b4]{ opacity: 0.7;
}
.custommall-order-context .order-context-body .order-param[data-v-565398b4]{ padding-left: 10px;
}
.custommall-order-context .order-context-body .order-price del[data-v-565398b4]{ display: block; color: #CCC; text-align: right;
}
.custommall-order-context .order-context-body .order-quantity[data-v-565398b4]{ height: 50px; line-height: 50px; text-align: right;
}
.custommall-order-context .order-footer[data-v-565398b4]{ height: 50px; line-height: 50px; text-align: right; padding-right: 15px; padding-left: 15px;
}
.custommall-order-context .order-footer-price[data-v-565398b4]{ font-weight: bold; font-size: 16px;
}
.custommall-order-context .order-footer-price-sub[data-v-565398b4]{ font-size: 12px;
}
.custommall-order-context .order-price[data-v-565398b4]{ color: #EB3C38; padding-left: 5px;
}
.custommall-order-context .order-state[data-v-565398b4]{ text-align: right;
}
.custommall-order-context .order-staff[data-v-565398b4]{ display: block; width: 100%; font-size: 12px; color: #999; padding-right: 15px; padding-left: 15px; padding-bottom: 15px; font-size: 12px; color: #999;
}
.custommall-order-context .order-button[data-v-565398b4]{ text-align: right; padding-right: 15px; padding-left: 15px; padding-bottom: 15px; clear: both;
}
.custommall-order-context .order-button button[data-v-565398b4]{ border-radius: 15px; border: 1px solid #C3C3C3; background-color: transparent; color: #C3C3C3; font-size: 12px; padding-right: 10px; padding-left: 10px; padding-top: 5px; padding-bottom: 5px; margin-left: 10px;
}
.custommall-order-context .order-button button.confirm[data-v-565398b4]{ border: 1px solid #EB3C38; background-color: transparent; color: #EB3C38;
}
.custommall-order-extend[data-v-565398b4]{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(255,255,255,1);
}
.custommall-order-extend.custommall-order-modal-from[data-v-565398b4]{ background-color: rgba(0,0,0,0.5);
}
.custommall-order-modal-from .order-modal-from-close[data-v-565398b4]{ width: 280px; margin: 0 auto; height: 40px;
}
.custommall-order-modal-from .order-modal-from-close .icon-close[data-v-565398b4]{ display: block; width: 40px; height: 40px; line-height: 40px; float: right; background: url(/static/images/icon-close.png) center no-repeat; background-size: 20px;
}
.custommall-order-modal-from .order-modal-from[data-v-565398b4]{ width: 280px; margin: 0 auto; background-color: #FFF; border-radius: 8px; padding-right: 20px; padding-left: 20px; padding-bottom: 20px;
}
.custommall-order-modal-from .order-modal-from-headline[data-v-565398b4]{ height: 70px; line-height: 70px; font-size: 20px; font-weight: bold;
}
.custommall-order-modal-from .order-modal-from-select[data-v-565398b4]{ border: 1px solid #EBEBEB; background: transparent; height: 40px; line-height: 40px; margin-bottom: 10px; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 0px 8px;
}
.custommall-order-modal-from .order-modal-from-input[data-v-565398b4]{ border: 1px solid #EBEBEB; height: 40px; line-height: 40px; margin-bottom: 10px; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 0px 8px;
}
.custommall-order-modal-from .order-modal-from-textarea[data-v-565398b4]{ border: 1px solid #EBEBEB; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 8px;
}
.custommall-order-modal-from .order-modal-from-button[data-v-565398b4]{ height: 40px; line-height: 40px; margin-top: 20px; color: #FFF; border-radius: 20px; border-color: #FF6068; background: -webkit-gradient(linear, left top, left bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(#FE4B4B, #E81B16);
}
.custommall-order-header[data-v-565398b4]{ height: 40px; line-height: 40px; text-align: center; background-color: #FFF;
}
.custommall-order-header .order-state-item[data-v-565398b4]{ position: relative;
}
.custommall-order-header .order-state-item[data-v-565398b4]::after{ content: ''; position: absolute; right: 0; bottom: 0; left: 0; height: 2px; overflow: hidden; background: transparent;
}
.custommall-order-header .order-state-item.active[data-v-565398b4]{ color: #EB3C38;
}
.custommall-order-header .order-state-item.active[data-v-565398b4]::after{ background: #EB3C38;
}
.order-modal-from-staff[data-v-565398b4] { color: #999; padding: 10px 0; margin: 0 10px; border: 1px solid #eaeaea; border-left: none; border-right: none;
}
.custommall-ranking-header[data-v-565398b4]{ position: relative;
}
.custommall-ranking-search-content[data-v-565398b4]{ height: 40px; line-height: 40px; position: relative; font-size: 14px; margin-top: 50px; background-color: #FFF;
}
.custommall-ranking-search-content[data-v-565398b4]::after{ content: ''; position: absolute; right: 0; bottom: 0; left: 0; height: 1px; overflow: hidden; background: #EBEBEB;
}
.custommall-ranking-search-content .input-container[data-v-565398b4]{ padding-right: 10px; padding-left: 10px; overflow: hidden; position: relative;
}
.custommall-ranking-search-content .input-container input[data-v-565398b4]{ display: block; width: 100%; height: 40px; line-height: 1em; border: 0; background-color: transparent; outline: none;
}
.custommall-ranking-search-content .input-button[data-v-565398b4]{ width: 60px; height: 40px; background: url(/static/images/icon-search.png) 50% 50% no-repeat; background-size: 20px;
}
.custommall-ranking-search-content .icon-sorting-button[data-v-565398b4]{ width: 40px; height: 40px; background: url(/static/images/icon-sorting.png) 50% 50% no-repeat; background-size: 20px;
}
.custommall-ranking-sorting[data-v-565398b4]{ position: absolute; right: 0px; top: 39px; width: 120px; background-color: #FFF; z-index: 99;
}
.custommall-ranking-sorting-item[data-v-565398b4]{ height: 28px; line-height: 28px; padding-left: 15px; padding-right: 15px; overflow: hidden; border-left: solid 2px transparent; color: #999; position: relative;
}
.custommall-ranking-sorting-item.active[data-v-565398b4]{ color: #EB3C38; border-left-color: #EB3C38;
}
.custommall-ranking-sorting-item[data-v-565398b4]::after{ content: ''; position: absolute; right: 10px; bottom: 0; left: 10px; height: 1px; overflow: hidden; background: #EBEBEB;
}
.custommall-ranking-sorting-item[data-v-565398b4]::before{ content: ''; display: inline-block; width: 0; height: 0; border: 4px solid #999; float: right; margin-top: 12px; border-color: #999 transparent transparent transparent;
}
.custommall-ranking-sorting-item.order-desc[data-v-565398b4]::before{ border-color: transparent transparent #999 transparent; margin-top: 8px;
}
.ProCustomSDiv[data-v-565398b4]{position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 9;
}
.ProCustomSDiv .ProCustomSDivYY[data-v-565398b4]{ width: 100%; height: 100%; background-color: #333; opacity: .7;
}
.ProCustomSDiv-container[data-v-565398b4]{ position: absolute; top: 0; left: 0; width: 100%; height: 100%;
}
.ProCustomSDiv .ProCustomSDivNav[data-v-565398b4]{width: 70%; background-color: #fff; border-radius: 10px; -webkit-border-radius: 10px; -moz-border-radius: 10px; padding: 14px; -webkit-box-sizing: border-box; box-sizing: border-box;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs[data-v-565398b4]{
    width: 100%; height: 120px; padding: 15px; padding-left: 100px; padding-right: 0; border-bottom: 1px solid #ccc; -webkit-box-sizing: border-box; box-sizing: border-box; position: relative;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div[data-v-565398b4]:nth-child(1){position: absolute; width: 90px; height: 90px; top: 15px; left: 0; border: 1px solid #bbb; -webkit-box-sizing: border-box; box-sizing: border-box; border-radius: 8px; -webkit-border-radius: 8px; -moz-border-radius: 8px; overflow: hidden;
}
.flex-center[data-v-565398b4]{ display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center;
}

.app-screen[data-v-49bdc7f9] { position: relative; width: 100%; height: 100%; -webkit-perspective: 1200px; perspective: 1200px; -webkit-transform-style: preserve-3d; transform-style: preserve-3d; background-color: #FFF;
}
.app-screen-main[data-v-49bdc7f9] { -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0);
}
.app-screen-container[data-v-49bdc7f9]{ padding-top: 30px; padding-right: 10px; padding-left: 10px; position: relative;
}
.app-screen-container .step-enroll[data-v-49bdc7f9]{ position: relative; font-size: 16px; border-bottom: 1px solid #EBEBEB; padding-bottom: 15px; padding-top: 12px;
}
.app-screen-container .step-enroll .identifier[data-v-49bdc7f9]{ position: absolute; top: 12px; left: 0; width: 50px; height: 25px; line-height: 25px; color: #CCC; text-align: center;
}
.app-screen-container .step-enroll .username-whole[data-v-49bdc7f9]{ color: #999; padding-left: 10px;
}
.app-screen-container .step-enroll .username[data-v-49bdc7f9]{ padding-right: 10px; padding-left: 3px;
}
.app-screen-container .step-enroll .col-default[data-v-49bdc7f9]{ padding-right: 10px; padding-left: 10px;
}
.app-screen-container .step-enroll input[data-v-49bdc7f9]{ border: 0; background-color: transparent; width: 100%; height: 25px; line-height: 25px; padding-left: 50px; outline: none;
}
.app-screen-container .step-notes[data-v-49bdc7f9]{ font-size: 12px; text-align: right; color: #B5B6CA; padding-top: 18px; padding-bottom: 18px; font-size: 14px;
}
.app-screen-container .step-notes.align-left[data-v-49bdc7f9]{ text-align: left;
}
.app-screen-container .step-default-notes[data-v-49bdc7f9]{ font-size: 12px; color: #999;
}
.app-screen-container .step-tools[data-v-49bdc7f9]{ margin-top: 20px; text-align: center; height: 22px; line-height: 22px;
}
.app-screen-container .step-container[data-v-49bdc7f9]{ padding-top: 30px; text-align: center; font-size: 16px;
}
.app-screen-container .step-button[data-v-49bdc7f9]{ display: block; width: 100%; height: 50px; line-height: 50px; text-align: center; background-color: #CCCCCC; color: #FFF; border-radius: 25px; border: 1px solid transparent;
}
.app-screen-container .step-button.active[data-v-49bdc7f9]{ border-color: #FF6068; background: -webkit-gradient(linear, left top, left bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(#FE4B4B, #E81B16);
}
.app-screen-container .step-button.ignore[data-v-49bdc7f9]{ margin-bottom: 8px; background-color: transparent; color: #FF6068;
}
.app-screen-container .success-container[data-v-49bdc7f9]{ padding-top: 50px; padding-right: 30px; padding-left: 30px; text-align: center;
}
.app-screen-container .success-message[data-v-49bdc7f9]{ line-height: 35px; padding-top: 20px;
}


.custommall-order-section[data-v-d0006f8e]{ padding-top: 10px;
}
.custommall-order-body[data-v-d0006f8e]{ position: relative; background-color: #FFF;
}
.custommall-order-headline[data-v-d0006f8e]{ height: 40px; line-height: 40px; overflow: hidden; padding-right: 10px; padding-left: 10px; color: #555;
}
.custommall-order-headline .order-state[data-v-d0006f8e]{ color: #EB3C38;
}
.custommall-order-context[data-v-d0006f8e]{ position: relative; display: block; overflow: hidden;
}
.custommall-order-context .order-context[data-v-d0006f8e]{ height: 100px; padding-left: 110px; padding-right: 10px; position: relative; overflow: hidden; margin-top: 10px;
}
.custommall-order-context .order-context[data-v-d0006f8e]:first-child{ margin-top: 0px !important;
}
.custommall-order-context .order-context-image[data-v-d0006f8e]{ position: absolute; top: 5px; bottom: 5px; left: 10px; width: 90px; background-color: #FAFAFA; border-radius: 6px; overflow: hidden; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;
}
.custommall-order-context .order-context-image img[data-v-d0006f8e]{ max-width: 100%; max-height: 100%;
}
.custommall-order-context .order-context-body[data-v-d0006f8e]{ line-height: 25px;
}
.custommall-order-context .order-context-body .order-headline[data-v-d0006f8e]{ height: 50px; line-height: 25px;
}
.custommall-order-context .order-context-body .order-custom[data-v-d0006f8e]{ opacity: 0.7;
}
.custommall-order-context .order-context-body .order-param[data-v-d0006f8e]{ padding-left: 10px;
}
.custommall-order-context .order-context-body .order-price del[data-v-d0006f8e]{ display: block; color: #CCC;
}
.custommall-order-context .order-context-body .order-quantity[data-v-d0006f8e]{ height: 50px; line-height: 50px; text-align: right;
}
.custommall-order-context .order-footer[data-v-d0006f8e]{ height: 50px; line-height: 50px; text-align: right; padding-right: 15px; padding-left: 15px;
}
.custommall-order-context .order-footer-price[data-v-d0006f8e]{ font-weight: bold; font-size: 16px;
}
.custommall-order-context .order-footer-price-sub[data-v-d0006f8e]{ font-size: 12px;
}
.custommall-order-context .order-price[data-v-d0006f8e]{ color: #EB3C38; padding-left: 5px;
}
.custommall-order-context .order-state[data-v-d0006f8e]{ text-align: right;
}
.custommall-order-context .order-staff[data-v-d0006f8e]{ display: block; width: 100%; font-size: 12px; color: #999; padding-right: 15px; padding-left: 15px; padding-bottom: 15px; font-size: 12px; color: #999;
}
.custommall-order-context .order-button[data-v-d0006f8e]{ text-align: right; padding-right: 15px; padding-left: 15px; padding-bottom: 15px; clear: both;
}
.custommall-order-context .order-button button[data-v-d0006f8e]{ border-radius: 15px; border: 1px solid #C3C3C3; background-color: transparent; color: #C3C3C3; font-size: 12px; padding-right: 10px; padding-left: 10px; padding-top: 5px; padding-bottom: 5px; margin-left: 10px;
}
.custommall-order-context .order-button button.confirm[data-v-d0006f8e]{ border: 1px solid #EB3C38; background-color: transparent; color: #EB3C38;
}
.custommall-order-extend[data-v-d0006f8e]{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(255,255,255,1);
}
.custommall-order-extend.custommall-order-modal-from[data-v-d0006f8e]{ background-color: rgba(0,0,0,0.5);
}
.custommall-order-modal-from .order-modal-from-close[data-v-d0006f8e]{ width: 280px; margin: 0 auto; height: 40px;
}
.custommall-order-modal-from .order-modal-from-close .icon-close[data-v-d0006f8e]{ display: block; width: 40px; height: 40px; line-height: 40px; float: right; background: url(/static/images/icon-close.png) center no-repeat; background-size: 20px;
}
.custommall-order-modal-from .order-modal-from[data-v-d0006f8e]{ width: 280px; margin: 0 auto; background-color: #FFF; border-radius: 8px; padding-right: 20px; padding-left: 20px; padding-bottom: 20px;
}
.custommall-order-modal-from .order-modal-from-headline[data-v-d0006f8e]{ height: 70px; line-height: 70px; font-size: 20px; font-weight: bold;
}
.custommall-order-modal-from .order-modal-from-select[data-v-d0006f8e]{ border: 1px solid #EBEBEB; background: transparent; height: 40px; line-height: 40px; margin-bottom: 10px; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 0px 8px;
}
.custommall-order-modal-from .order-modal-from-input[data-v-d0006f8e]{ border: 1px solid #EBEBEB; height: 40px; line-height: 40px; margin-bottom: 10px; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 0px 8px;
}
.custommall-order-modal-from .order-modal-from-textarea[data-v-d0006f8e]{ border: 1px solid #EBEBEB; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 8px;
}
.custommall-order-modal-from .order-modal-from-button[data-v-d0006f8e]{ height: 40px; line-height: 40px; margin-top: 20px; color: #FFF; border-radius: 20px; border-color: #FF6068; background: -webkit-gradient(linear, left top, left bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(#FE4B4B, #E81B16);
}
.custommall-order-header[data-v-d0006f8e]{ height: 40px; line-height: 40px; text-align: center; background-color: #FFF; overflow-x: scroll; overflow-y: hidden; -webkit-overflow-scrolling: touch;
}
.custommall-order-header[data-v-d0006f8e]::-webkit-scrollbar{ display: none;
}
.custommall-order-header .order-state-item[data-v-d0006f8e]{ position: relative; display: inline-block; width: auto; padding: 0 15px;
}
.custommall-order-header .order-state-item[data-v-d0006f8e]::after{ content: ''; position: absolute; right: 0; bottom: 0; left: 0; height: 2px; overflow: hidden; background: transparent;
}
.custommall-order-header .order-state-item.active[data-v-d0006f8e]{ color: #EB3C38;
}
.custommall-order-header .order-state-item.active[data-v-d0006f8e]::after{ background: #EB3C38;
}
.order-modal-from-staff[data-v-d0006f8e] { color: #999; padding: 10px 0; margin: 0 10px; border: 1px solid #eaeaea; border-left: none; border-right: none;
}
.custommall-ranking-header[data-v-d0006f8e]{ position: relative; padding-top: 50px;
}
.custommall-ranking-search-content[data-v-d0006f8e]{ height: 40px; line-height: 40px; position: relative; font-size: 14px; background-color: #FFF;
}
.custommall-ranking-search-content[data-v-d0006f8e]::after{ content: ''; position: absolute; right: 0; bottom: 0; left: 0; height: 1px; overflow: hidden; background: #EBEBEB;
}
.custommall-ranking-search-content .input-container[data-v-d0006f8e]{ padding-right: 10px; padding-left: 10px; overflow: hidden; position: relative;
}
.custommall-ranking-search-content .input-container input[data-v-d0006f8e]{ display: block; width: 100%; height: 40px; line-height: 1em; border: 0; background-color: transparent; outline: none;
}
.custommall-ranking-search-content .input-button[data-v-d0006f8e]{ width: 60px; height: 40px; background: url(/static/images/icon-search.png) 50% 50% no-repeat; background-size: 20px;
}
.custommall-ranking-search-content .icon-sorting-button[data-v-d0006f8e]{ width: 40px; height: 40px; background: url(/static/images/icon-sorting.png) 50% 50% no-repeat; background-size: 20px;
}
.icon-shop-button[data-v-d0006f8e]{ width: 40px; height: 40px; background: url(/static/images/icon-shop.png) 50% 50% no-repeat; background-size: 23px;
}
.icon-date-button[data-v-d0006f8e]{ width: 40px; height: 40px; background: url(/static/images/icon-date.png) 50% 50% no-repeat; background-size: 23px;
}
.custommall-ranking-sorting[data-v-d0006f8e]{ position: absolute; right: 0px; width: 120px; background-color: #FFF; z-index: 99;
}
.custommall-ranking-sorting-item[data-v-d0006f8e]{ height: 28px; line-height: 28px; padding-left: 15px; padding-right: 15px; overflow: hidden; border-left: solid 2px transparent; color: #999; position: relative;
}
.custommall-ranking-sorting-item.active[data-v-d0006f8e]{ color: #EB3C38; border-left-color: #EB3C38;
}
.custommall-ranking-sorting-item[data-v-d0006f8e]::after{ content: ''; position: absolute; right: 10px; bottom: 0; left: 10px; height: 1px; overflow: hidden; background: #EBEBEB;
}
.custommall-ranking-sorting-item[data-v-d0006f8e]::before{ content: ''; display: inline-block; width: 0; height: 0; border: 4px solid #999; float: right; margin-top: 12px; border-color: #999 transparent transparent transparent;
}
.custommall-ranking-sorting-item.order-desc[data-v-d0006f8e]::before{ border-color: transparent transparent #999 transparent; margin-top: 8px;
}
.remove-sorting[data-v-d0006f8e]:before{ display: none;
}
.text-red[data-v-d0006f8e]{ color: #EB3C38;
}
.custommall-ranking-header-people[data-v-d0006f8e]{ width: 100%; height: 40px; line-height: 40px; background-color: #fff; padding: 0 10px;
}
.equipment-modal-from-checkbox[data-v-d0006f8e]{ display: block; text-align: left; font-weight: normal; font-size: 12px;
}
.equipment-modal-from-checkbox input[data-v-d0006f8e]{ top: 2px; position: relative; margin-right: 8px;
}
.order-state-container[data-v-d0006f8e]{ white-space: nowrap;
}
.text-danger[data-v-d0006f8e]{ color: #EB3C38;
}
.top-tips[data-v-d0006f8e] { padding: 5px 10px; background-color: #fef8e8; color: #eab033; font-size: 12px;
}
.top-tips-icon[data-v-d0006f8e] { margin-right: 5px; color: #fea003;
}
.selectd-date[data-v-d0006f8e] { padding: 10px 5px 0 5px; color: #999;
}
.shop-name[data-v-d0006f8e]{ width: 100px;
}

.ProCustomSDiv[data-v-55f24948]{position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 9;
}
.ProCustomSDiv .ProCustomSDivYY[data-v-55f24948]{ width: 100%; height: 100%; background-color: #333; opacity: .7
}
.ProCustomSDiv-container[data-v-55f24948]{ position: absolute; top: 0; left: 0; width: 100%; height: 100%;
}
.ProCustomSDiv .ProCustomSDivNav[data-v-55f24948]{width: 70%; background-color: #fff; position: absolute; left: 50%; top: 180px; -webkit-transform: translateX(-50%); transform: translateX(-50%); border-radius: 10px; -webkit-border-radius: 10px; -moz-border-radius: 10px; padding: 14px; -webkit-box-sizing: border-box; box-sizing: border-box;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs[data-v-55f24948]{
    width: 100%; height: 120px; padding: 15px; padding-left: 100px; padding-right: 0; border-bottom: 1px solid #ccc; -webkit-box-sizing: border-box; box-sizing: border-box; position: relative;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div[data-v-55f24948]:nth-child(1){position: absolute; width: 90px; height: 90px; top: 15px; left: 0; border: 1px solid #bbb; -webkit-box-sizing: border-box; box-sizing: border-box; border-radius: 8px; -webkit-border-radius: 8px; -moz-border-radius: 8px; overflow: hidden;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div[data-v-55f24948]:nth-child(2){width: 100%; height: 100%;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div:nth-child(2) span[data-v-55f24948]{display: block; font-size: 13px; color: #333;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div img[data-v-55f24948]{position: absolute; left: 50%; -webkit-transform: translateX(-50%); transform: translateX(-50%); height: 100%;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs[data-v-55f24948]:nth-child(3) {padding-left: 72%;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs:nth-child(3) div[data-v-55f24948]:nth-child(1){width: 72%; text-align: center;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs:nth-child(3) div[data-v-55f24948]:last-of-type{text-align: center;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs:nth-child(3) .orderDataImgPlayer[data-v-55f24948]{ width: 30px; height: 30px; position: absolute; top: 20px; left: 8px;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs:nth-child(3) .orderDataImgPlayer img[data-v-55f24948]{ display: block; width: 100%; height: 100%;border: none;
}
.ProCustomSDiv .orderBigImage[data-v-55f24948]{position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: #000; z-index: 999;
}
.ProCustomSDiv .orderBigImage img[data-v-55f24948]{display: block; width: 60%; height: auto; margin:200px auto;
}
.custommall-order-header[data-v-55f24948]{ position: relative; overflow: hidden;
}
.custommall-order-header[data-v-55f24948]::after{ content: ''; position: absolute; top: 0; right: 0; left: 0; height: 70%; background: -webkit-gradient(linear, left top, right bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(to bottom right, #FE4B4B, #E81B16);
}
.custommall-order-header-container[data-v-55f24948]{ margin: 30px 15px; padding-left: 70px; position: relative; z-index: 9;
}
.custommall-order-header-container .member-head[data-v-55f24948]{ width: 60px; height: 60px; overflow: hidden; position: absolute; top: 0; left: 0; background-color: #FFF; border-radius: 50%;
}
.custommall-order-header-container .member-head.avatar[data-v-55f24948]{ background-image: url(/static/images/icon-avatar.png); background-position: center; background-repeat: no-repeat; background-size: contain;
}
.custommall-order-header-container .member-head img[data-v-55f24948]{ max-width: 100%; max-height: 100%; vertical-align: inherit;
}
.custommall-order-header-container .member-name[data-v-55f24948]{ font-size: 18px; color: #FFF; height: 30px; line-height: 30px;
}
.custommall-order-header-container .member-group[data-v-55f24948]{ display: inline-block; position: relative; padding-top: 4px; padding-bottom: 4px; padding-right: 6px; padding-left: 6px; background-color: #FFF; border-radius: 20px; color: #989FCA; font-size: 12px; margin-bottom: 5px;
}
.custommall-order-header-address[data-v-55f24948]{ margin: 0 15px 15px; padding-bottom: 15px; overflow: hidden; display: block; border-radius: 6px; background-color: #FFF; position: relative; z-index: 9;
}
.custommall-order-header-address .order-address-icon[data-v-55f24948]{ width: 35px; height: 60px; display: block; background: url(/static/images/icon-position.png) center no-repeat; background-size: 25px;
}
.custommall-order-header-address .order-address-headline[data-v-55f24948]{ height: 50px; line-height: 50px; text-align: left; padding-left: 5px; padding-right: 20px; font-size: 16px;
}
.custommall-order-header-address .order-address-context[data-v-55f24948]{ padding-left: 5px; padding-right: 15px; font-size:12px; opacity: 0.7;
}
.order-detailed[data-v-55f24948]{ padding-top: 15px; padding-bottom: 15px; padding-right: 10px; padding-left: 10px;
}
.order-detailed-line[data-v-55f24948]{ height: 23px; line-height: 23px; font-size: 12px;
}
.custommall-order-section[data-v-55f24948]{ padding-top: 0;
}
.custommall-order-body[data-v-55f24948]{ position: relative; background-color: #FFF;
}
.custommall-order-context[data-v-55f24948]{ position: relative; display: block; overflow: hidden;
}
.custommall-order-context .order-context[data-v-55f24948]{ height: 100px; padding-left: 110px; padding-right: 10px; position: relative; overflow: hidden; margin-top: 10px;
}
.custommall-order-context .order-context-image[data-v-55f24948]{ position: absolute; top: 5px; bottom: 5px; left: 10px; width: 90px; background-color: #FAFAFA; border-radius: 6px; overflow: hidden; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;
}
.custommall-order-context .order-context-image img[data-v-55f24948]{ max-width: 100%; max-height: 100%;
}
.custommall-order-context .order-context-body[data-v-55f24948]{ line-height: 25px;
}
.custommall-order-context .order-context-body .order-headline[data-v-55f24948]{ height: 50px; line-height: 25px;
}
.custommall-order-context .order-context-body .order-custom[data-v-55f24948]{ opacity: 0.7;
}
.custommall-order-context .order-context-body .order-custom .order-custom-span[data-v-55f24948]{ color: blue; border-bottom: 1px solid #0000FF;padding:0 4px;margin:0 4px;-webkit-box-sizing: border-box;box-sizing: border-box;
}
.custommall-order-context .order-context-body .order-param[data-v-55f24948]{ padding-left: 10px;
}
.custommall-order-context .order-context-body .order-price del[data-v-55f24948]{ display: block; color: #CCC;
}
.custommall-order-context .order-context-body .order-quantity[data-v-55f24948]{ height: 50px; line-height: 50px; text-align: right;
}
.custommall-order-context .order-footer[data-v-55f24948]{ height: 50px; line-height: 50px; text-align: right; padding-right: 15px; padding-left: 15px;
}
.custommall-order-context .order-footer-price[data-v-55f24948]{ font-weight: bold; font-size: 16px;
}
.custommall-order-context .order-footer-price-sub[data-v-55f24948]{ font-size: 12px;
}
.custommall-order-context .order-button[data-v-55f24948]{ text-align: right; padding-right: 15px; padding-left: 15px; padding-bottom: 15px;
}
.custommall-order-context .order-button button[data-v-55f24948]{ border-radius: 15px; border: 1px solid #555; background-color: transparent; color: #555; font-size: 12px; padding-right: 10px; padding-left: 10px; padding-top: 5px; padding-bottom: 5px; margin-left: 10px;
}
.custommall-order-context .order-button button.confirm[data-v-55f24948]{ border: 1px solid #EB3C38; background-color: transparent; color: #EB3C38;
}
.custommall-order-detailed[data-v-55f24948]{ margin-top: 15px; background-color: #FFF; padding-right: 10px; padding-left: 10px; font-size: 12px; padding-top: 10px; padding-bottom: 10px;
}
.custommall-order-detailed .order-container-line[data-v-55f24948]{ height: 22px; line-height: 22px;
}
.auto-height[data-v-55f24948]{ height: auto !important;
}
.custommall-order-extend[data-v-55f24948]{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(255,255,255,1);
}
.custommall-order-extend.custommall-order-modal-from[data-v-55f24948]{ background-color: rgba(0,0,0,0.5);
}
.custommall-order-modal-from .order-modal-from-close[data-v-55f24948]{ width: 280px; margin: 0 auto; height: 40px;
}
.custommall-order-modal-from .order-modal-from-close .icon-close[data-v-55f24948]{ display: block; width: 40px; height: 40px; line-height: 40px; float: right; background: url(/static/images/icon-close.png) center no-repeat; background-size: 20px;
}
.custommall-order-modal-from .order-modal-from[data-v-55f24948]{ width: 280px; margin: 0 auto; background-color: #FFF; border-radius: 8px; padding-right: 20px; padding-left: 20px; padding-bottom: 20px;
}
.custommall-order-modal-from .order-modal-from-headline[data-v-55f24948]{ height: 70px; line-height: 70px; font-size: 20px; font-weight: bold;
}
.custommall-order-modal-from .order-modal-from-textarea[data-v-55f24948]{ border: 1px solid #EBEBEB; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 8px;
}
.custommall-order-modal-from .order-modal-from-button[data-v-55f24948]{ height: 40px; line-height: 40px; margin-top: 20px; color: #FFF; border-radius: 20px; border-color: #FF6068; background: -webkit-gradient(linear, left top, left bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(#FE4B4B, #E81B16);
}
.custommall-order-footer[data-v-55f24948]{ display: block; text-align: left; padding-left: 10px; color: #333;
}
.custommall-order-footer .footer-content[data-v-55f24948]{ height: 50px; line-height: 50px; font-size: 16px;
}
.custommall-order-footer .order-price[data-v-55f24948]{ font-weight: bold; font-size: 16px; color: #E81B16;
}
.custommall-order-footer .order-price-sub[data-v-55f24948]{ font-size: 12px; margin-right: 4px;
}
.flex-center[data-v-55f24948]{ display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center;
}
.text-xs[data-v-55f24948]{ font-size: 12px;
}
.express-detail[data-v-55f24948]{ height: 400px; overflow-y: auto; -webkit-overflow-scrolling: touch;
}

.custommall-order-section[data-v-e2b86b44]{ padding-top: 10px;
}
.custommall-order-body[data-v-e2b86b44]{ position: relative; background-color: #FFF;
}
.custommall-order-headline[data-v-e2b86b44]{ height: 40px; line-height: 40px; overflow: hidden; padding-right: 10px; padding-left: 10px; color: #555;
}
.custommall-order-headline .order-state[data-v-e2b86b44]{ color: #EB3C38;
}
.custommall-order-context[data-v-e2b86b44]{ position: relative; display: block; overflow: hidden;
}
.custommall-order-context .order-context[data-v-e2b86b44]{ height: 100px; padding-left: 110px; padding-right: 10px; position: relative; overflow: hidden; margin-top: 10px;
}
.custommall-order-context .order-context[data-v-e2b86b44]:first-child{ margin-top: 0px !important;
}
.custommall-order-context .order-context-image[data-v-e2b86b44]{ position: absolute; top: 5px; bottom: 5px; left: 10px; width: 90px; background-color: #FAFAFA; border-radius: 6px; overflow: hidden; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center;
}
.custommall-order-context .order-context-image img[data-v-e2b86b44]{ max-width: 100%; max-height: 100%;
}
.custommall-order-context .order-context-body[data-v-e2b86b44]{ line-height: 25px;
}
.custommall-order-context .order-context-body .order-headline[data-v-e2b86b44]{ height: 50px; line-height: 25px;
}
.custommall-order-context .order-context-body .order-custom[data-v-e2b86b44]{ opacity: 0.7;
}
.custommall-order-context .order-context-body .order-param[data-v-e2b86b44]{ padding-left: 10px;
}
.custommall-order-context .order-context-body .order-price del[data-v-e2b86b44]{ display: block; color: #CCC; text-align: right;
}
.custommall-order-context .order-context-body .order-quantity[data-v-e2b86b44]{ height: 50px; line-height: 50px; text-align: right;
}
.custommall-order-context .order-footer[data-v-e2b86b44]{ height: 50px; line-height: 50px; text-align: right; padding-right: 15px; padding-left: 15px;
}
.custommall-order-context .order-footer-price[data-v-e2b86b44]{ font-weight: bold; font-size: 16px;
}
.custommall-order-context .order-footer-price-sub[data-v-e2b86b44]{ font-size: 12px;
}
.custommall-order-context .order-price[data-v-e2b86b44]{ color: #EB3C38; padding-left: 5px;
}
.custommall-order-context .order-state[data-v-e2b86b44]{ text-align: right;
}
.custommall-order-context .order-staff[data-v-e2b86b44]{ display: block; width: 100%; font-size: 12px; color: #999; padding-right: 15px; padding-left: 15px; padding-bottom: 15px; font-size: 12px; color: #999;
}
.custommall-order-context .order-button[data-v-e2b86b44]{ text-align: right; padding-right: 15px; padding-left: 15px; padding-bottom: 15px; clear: both;
}
.custommall-order-context .order-button button[data-v-e2b86b44]{ border-radius: 15px; border: 1px solid #C3C3C3; background-color: transparent; color: #C3C3C3; font-size: 12px; padding-right: 10px; padding-left: 10px; padding-top: 5px; padding-bottom: 5px; margin-left: 10px;
}
.custommall-order-context .order-button button.confirm[data-v-e2b86b44]{ border: 1px solid #EB3C38; background-color: transparent; color: #EB3C38;
}
.custommall-order-extend[data-v-e2b86b44]{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(255,255,255,1);
}
.custommall-order-extend.custommall-order-modal-from[data-v-e2b86b44]{ background-color: rgba(0,0,0,0.5);
}
.custommall-order-modal-from .order-modal-from-close[data-v-e2b86b44]{ width: 280px; margin: 0 auto; height: 40px;
}
.custommall-order-modal-from .order-modal-from-close .icon-close[data-v-e2b86b44]{ display: block; width: 40px; height: 40px; line-height: 40px; float: right; background: url(/static/images/icon-close.png) center no-repeat; background-size: 20px;
}
.custommall-order-modal-from .order-modal-from[data-v-e2b86b44]{ width: 280px; margin: 0 auto; background-color: #FFF; border-radius: 8px; padding-right: 20px; padding-left: 20px; padding-bottom: 20px;
}
.custommall-order-modal-from .order-modal-from-headline[data-v-e2b86b44]{ height: 70px; line-height: 70px; font-size: 20px; font-weight: bold;
}
.custommall-order-modal-from .order-modal-from-select[data-v-e2b86b44]{ border: 1px solid #EBEBEB; background: transparent; height: 40px; line-height: 40px; margin-bottom: 10px; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 0px 8px;
}
.custommall-order-modal-from .order-modal-from-input[data-v-e2b86b44]{ border: 1px solid #EBEBEB; height: 40px; line-height: 40px; margin-bottom: 10px; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 0px 8px;
}
.custommall-order-modal-from .order-modal-from-textarea[data-v-e2b86b44]{ border: 1px solid #EBEBEB; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 8px;
}
.custommall-order-modal-from .order-modal-from-button[data-v-e2b86b44]{ height: 40px; line-height: 40px; margin-top: 20px; color: #FFF; border-radius: 20px; border-color: #FF6068; background: -webkit-gradient(linear, left top, left bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(#FE4B4B, #E81B16);
}
.custommall-order-header[data-v-e2b86b44]{ height: 40px; line-height: 40px; text-align: center; background-color: #FFF; overflow-x: scroll; overflow-y: hidden; -webkit-overflow-scrolling: touch;
}
.custommall-order-header .order-state-item[data-v-e2b86b44]{ position: relative; display: inline-block; width: auto; padding: 0 15px;
}
.custommall-order-header .order-state-item[data-v-e2b86b44]::after{ content: ''; position: absolute; right: 0; bottom: 0; left: 0; height: 2px; overflow: hidden; background: transparent;
}
.custommall-order-header .order-state-item.active[data-v-e2b86b44]{ color: #EB3C38;
}
.custommall-order-header .order-state-item.active[data-v-e2b86b44]::after{ background: #EB3C38;
}
.order-modal-from-staff[data-v-e2b86b44]{ color: #999; padding: 10px 0; margin: 0 10px; border: 1px solid #eaeaea; border-left: none; border-right: none;
}
.order-state-container[data-v-e2b86b44]{ white-space: nowrap;
}
.custommall-order-header[data-v-e2b86b44]::-webkit-scrollbar{ display: none;
}
.custommall-ranking-header[data-v-e2b86b44]{ position: relative;
}
.custommall-ranking-search-content[data-v-e2b86b44]{ height: 40px; line-height: 40px; position: relative; font-size: 14px; margin-top: 50px; background-color: #FFF;
}
.custommall-ranking-search-content[data-v-e2b86b44]::after{ content: ''; position: absolute; right: 0; bottom: 0; left: 0; height: 1px; overflow: hidden; background: #EBEBEB;
}
.custommall-ranking-search-content .input-container[data-v-e2b86b44]{ padding-right: 10px; padding-left: 10px; overflow: hidden; position: relative;
}
.custommall-ranking-search-content .input-container input[data-v-e2b86b44]{ display: block; width: 100%; height: 40px; line-height: 1em; border: 0; background-color: transparent; outline: none;
}
.custommall-ranking-search-content .input-button[data-v-e2b86b44]{ width: 60px; height: 40px; background: url(/static/images/icon-search.png) 50% 50% no-repeat; background-size: 20px;
}
.custommall-ranking-search-content .icon-sorting-button[data-v-e2b86b44]{ width: 40px; height: 40px; background: url(/static/images/icon-sorting.png) 50% 50% no-repeat; background-size: 20px;
}
.custommall-ranking-sorting[data-v-e2b86b44]{ position: absolute; right: 0px; top: 39px; width: 120px; background-color: #FFF; z-index: 99;
}
.custommall-ranking-sorting-item[data-v-e2b86b44]{ height: 28px; line-height: 28px; padding-left: 15px; padding-right: 15px; overflow: hidden; border-left: solid 2px transparent; color: #999; position: relative;
}
.custommall-ranking-sorting-item.active[data-v-e2b86b44]{ color: #EB3C38; border-left-color: #EB3C38;
}
.custommall-ranking-sorting-item[data-v-e2b86b44]::after{ content: ''; position: absolute; right: 10px; bottom: 0; left: 10px; height: 1px; overflow: hidden; background: #EBEBEB;
}
.custommall-ranking-sorting-item[data-v-e2b86b44]::before{ content: ''; display: inline-block; width: 0; height: 0; border: 4px solid #999; float: right; margin-top: 12px; border-color: #999 transparent transparent transparent;
}
.custommall-ranking-sorting-item.order-desc[data-v-e2b86b44]::before{ border-color: transparent transparent #999 transparent; margin-top: 8px;
}
.order-custom-span[data-v-e2b86b44]{ color: blue; border-bottom: 1px solid #0000FF;padding:0 4px;margin:0 4px;-webkit-box-sizing: border-box;box-sizing: border-box;
}
.ProCustomSDiv[data-v-e2b86b44]{position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 9;
}
.ProCustomSDiv .ProCustomSDivYY[data-v-e2b86b44]{ width: 100%; height: 100%; background-color: #333; opacity: .7;
}
.ProCustomSDiv-container[data-v-e2b86b44]{ position: absolute; top: 0; left: 0; width: 100%; height: 100%;
}
.ProCustomSDiv .ProCustomSDivNav[data-v-e2b86b44]{width: 70%; background-color: #fff; border-radius: 10px; -webkit-border-radius: 10px; -moz-border-radius: 10px; padding: 14px; -webkit-box-sizing: border-box; box-sizing: border-box;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs[data-v-e2b86b44]{
    width: 100%; height: 120px; padding: 15px; padding-left: 100px; padding-right: 0; border-bottom: 1px solid #ccc; -webkit-box-sizing: border-box; box-sizing: border-box; position: relative;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div[data-v-e2b86b44]:nth-child(1){position: absolute; width: 90px; height: 90px; top: 15px; left: 0; border: 1px solid #bbb; -webkit-box-sizing: border-box; box-sizing: border-box; border-radius: 8px; -webkit-border-radius: 8px; -moz-border-radius: 8px; overflow: hidden;
}
.flex-center[data-v-e2b86b44]{ display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center;
}
.rotate-ring-content[data-v-e2b86b44]{ -webkit-line-clamp: 2;
}

.ProCustomSDiv[data-v-54a76df6]{position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 9;
}
.ProCustomSDiv .ProCustomSDivYY[data-v-54a76df6]{ width: 100%; height: 100%; background-color: #333; opacity: .7;
}
.ProCustomSDiv-container[data-v-54a76df6]{ position: absolute; top: 0; left: 0; width: 100%; height: 100%;
}
.ProCustomSDiv .ProCustomSDivNav[data-v-54a76df6]{width: 70%; background-color: #fff; border-radius: 10px; -webkit-border-radius: 10px; -moz-border-radius: 10px; padding: 14px; -webkit-box-sizing: border-box; box-sizing: border-box;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs[data-v-54a76df6]{
    width: 100%; height: 120px; padding: 15px; padding-left: 100px; padding-right: 0; border-bottom: 1px solid #ccc; -webkit-box-sizing: border-box; box-sizing: border-box; position: relative;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div[data-v-54a76df6]:nth-child(1){position: absolute; width: 90px; height: 90px; top: 15px; left: 0; border: 1px solid #bbb; -webkit-box-sizing: border-box; box-sizing: border-box; border-radius: 8px; -webkit-border-radius: 8px; -moz-border-radius: 8px; overflow: hidden;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div[data-v-54a76df6]:nth-child(2){width: 100%; height: 100%;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div:nth-child(2) span[data-v-54a76df6]{display: block; font-size: 13px; color: #333;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div img[data-v-54a76df6]{position: absolute; left: 50%; -webkit-transform: translateX(-50%); transform: translateX(-50%); height: 100%; width: 100%;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs[data-v-54a76df6]:nth-child(3) {padding-left: 72%;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs:nth-child(3) div[data-v-54a76df6]:nth-child(1){width: 72%; text-align: center;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs:nth-child(3) div[data-v-54a76df6]:last-of-type{text-align: center;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs:nth-child(3) .orderDataImgPlayer[data-v-54a76df6]{ width: 30px; height: 30px; position: absolute; top: 20px; left: 8px;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs:nth-child(3) .orderDataImgPlayer img[data-v-54a76df6]{ display: block; width: 100%; height: 100%;border: none;
}
.ProCustomSDiv .orderBigImage[data-v-54a76df6]{position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: #000; z-index: 999;
}
.ProCustomSDiv .orderBigImage img[data-v-54a76df6]{display: block; width: 60%; height: auto; margin:200px auto;
}
.custommall-order-header[data-v-54a76df6]{ position: relative; overflow: hidden;
}
.custommall-order-header[data-v-54a76df6]::after{ content: ''; position: absolute; top: 0; right: 0; left: 0; height: 145px; background: -webkit-gradient(linear, left top, right bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(to bottom right, #FE4B4B, #E81B16);
}
.custommall-order-header-container[data-v-54a76df6]{ margin: 30px 15px; padding-left: 70px; position: relative; z-index: 9;
}
.custommall-order-header-container .member-head[data-v-54a76df6]{ width: 60px; height: 60px; overflow: hidden; position: absolute; top: 0; left: 0; background-color: #FFF; border-radius: 50%;
}
.custommall-order-header-container .member-head.avatar[data-v-54a76df6]{ background-image: url(/static/images/icon-avatar.png); background-position: center; background-repeat: no-repeat; background-size: contain;
}
.custommall-order-header-container .member-head img[data-v-54a76df6]{ max-width: 100%; max-height: 100%; vertical-align: inherit;
}
.custommall-order-header-container .member-name[data-v-54a76df6]{ font-size: 18px; color: #FFF; height: 30px; line-height: 30px;
}
.custommall-order-header-container .member-group[data-v-54a76df6]{ display: inline-block; position: relative; padding-top: 4px; padding-bottom: 4px; padding-right: 6px; padding-left: 6px; background-color: #FFF; border-radius: 20px; color: #989FCA; font-size: 12px;
}
.custommall-order-header-address[data-v-54a76df6]{ margin: 0 15px 15px; padding-bottom: 15px; overflow: hidden; display: block; border-radius: 6px; background-color: #FFF; position: relative; z-index: 9;
}
.custommall-order-header-address .order-address-icon[data-v-54a76df6]{ width: 35px; height: 60px; display: block; background: url(/static/images/icon-position.png) center no-repeat; background-size: 25px;
}
.custommall-order-header-address .order-address-headline[data-v-54a76df6]{ height: 50px; line-height: 50px; text-align: left; padding-left: 5px; padding-right: 20px; font-size: 16px;
}
.custommall-order-header-address .order-address-context[data-v-54a76df6]{ padding-left: 5px; padding-right: 15px; font-size:12px; opacity: 0.7;
}
.order-detailed[data-v-54a76df6]{ padding-top: 15px; padding-bottom: 15px; padding-right: 10px; padding-left: 10px;
}
.order-detailed-line[data-v-54a76df6]{ height: 23px; line-height: 23px; font-size: 12px;
}
.custommall-order-section[data-v-54a76df6]{ padding-top: 0;
}
.custommall-order-body[data-v-54a76df6]{ position: relative; background-color: #FFF;
}
.custommall-order-context[data-v-54a76df6]{ position: relative; display: block; overflow: hidden;
}
.custommall-order-context .order-context[data-v-54a76df6]{ padding-left: 110px; padding-right: 10px; position: relative; overflow: hidden; margin-top: 10px;
}
.custommall-order-context .order-context-image[data-v-54a76df6]{ position: absolute; top: 5px; bottom: 5px; left: 10px; width: 90px; background-color: #FAFAFA; border-radius: 6px; overflow: hidden; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center;
}
.custommall-order-context .order-context-image img[data-v-54a76df6]{ max-width: 100%; max-height: 100%;
}
.custommall-order-context .order-context-body[data-v-54a76df6]{ line-height: 25px;
}
.custommall-order-context .order-context-body .order-headline[data-v-54a76df6]{ height: 50px; line-height: 25px;
}
.custommall-order-context .order-context-body .order-custom[data-v-54a76df6]{ opacity: 0.7;
}
.custommall-order-context .order-context-body .order-custom .order-custom-span[data-v-54a76df6]{ color: blue; border-bottom: 1px solid #0000FF;padding:0 4px;margin:0 4px;-webkit-box-sizing: border-box;box-sizing: border-box;
}
.custommall-order-context .order-context-body .order-param[data-v-54a76df6]{ padding-left: 10px;
}
.custommall-order-context .order-context-body .order-price del[data-v-54a76df6]{ display: block; color: #CCC; text-align: right;
}
.custommall-order-context .order-context-body .order-quantity[data-v-54a76df6]{ height: 50px; line-height: 50px; text-align: right;
}
.custommall-order-context .order-footer[data-v-54a76df6]{ height: 50px; line-height: 50px; text-align: right; padding-right: 15px; padding-left: 15px;
}
.custommall-order-context .order-footer-price[data-v-54a76df6]{ font-weight: bold; font-size: 16px;
}
.custommall-order-context .order-footer-price-sub[data-v-54a76df6]{ font-size: 12px;
}
.custommall-order-context .order-button[data-v-54a76df6]{ text-align: right; padding-right: 15px; padding-left: 15px; padding-bottom: 15px;
}
.custommall-order-context .order-button button[data-v-54a76df6]{ border-radius: 15px; border: 1px solid #555; background-color: transparent; color: #555; font-size: 12px; padding-right: 10px; padding-left: 10px; padding-top: 5px; padding-bottom: 5px; margin-left: 10px;
}
.custommall-order-context .order-button button.confirm[data-v-54a76df6]{ border: 1px solid #EB3C38; background-color: transparent; color: #EB3C38;
}
.custommall-order-detailed[data-v-54a76df6]{ margin-top: 15px; background-color: #FFF; padding-right: 10px; padding-left: 10px; font-size: 12px; padding-top: 10px; padding-bottom: 10px;
}
.custommall-order-detailed .order-container-line[data-v-54a76df6]{ height: 22px; line-height: 22px;
}
.custommall-order-extend[data-v-54a76df6]{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(255,255,255,1);
}
.custommall-order-extend.custommall-order-modal-from[data-v-54a76df6]{ background-color: rgba(0,0,0,0.5);
}
.custommall-order-modal-from .order-modal-from-close[data-v-54a76df6]{ width: 280px; margin: 0 auto; height: 40px;
}
.custommall-order-modal-from .order-modal-from-close .icon-close[data-v-54a76df6]{ display: block; width: 40px; height: 40px; line-height: 40px; float: right; background: url(/static/images/icon-close.png) center no-repeat; background-size: 20px;
}
.custommall-order-modal-from .order-modal-from[data-v-54a76df6]{ width: 280px; margin: 0 auto; background-color: #FFF; border-radius: 8px; padding-right: 20px; padding-left: 20px; padding-bottom: 20px;
}
.custommall-order-modal-from .order-modal-from-headline[data-v-54a76df6]{ height: 70px; line-height: 70px; font-size: 20px; font-weight: bold;
}
.custommall-order-modal-from .order-modal-from-textarea[data-v-54a76df6]{ border: 1px solid #EBEBEB; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 8px;
}
.custommall-order-modal-from .order-modal-from-button[data-v-54a76df6]{ height: 40px; line-height: 40px; margin-top: 20px; color: #FFF; border-radius: 20px; border-color: #FF6068; background: -webkit-gradient(linear, left top, left bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(#FE4B4B, #E81B16);
}
.custommall-order-footer[data-v-54a76df6]{ display: block; text-align: left; padding-left: 10px; color: #333;
}
.custommall-order-footer .footer-content[data-v-54a76df6]{ height: 50px; line-height: 50px; font-size: 16px;
}
.custommall-order-footer .order-price[data-v-54a76df6]{ font-weight: bold; font-size: 16px; color: #E81B16;
}
.custommall-order-footer .order-price-sub[data-v-54a76df6]{ font-size: 12px; margin-right: 4px;
}
.flex-center[data-v-54a76df6]{ display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center;
}
.order-container-list[data-v-54a76df6]{ padding-left: 20px; list-style: none;
}
.order-container-list-item[data-v-54a76df6]{ position: relative; padding-bottom: 10px;
}
.order-container-list-item[data-v-54a76df6]:before{ content: ""; position: absolute; left: -15px; top: 5px; width: 5px; height: 5px; border-radius: 50%; background-color: #e9e9e9; z-index: 2;
}
.order-container-list-item.active[data-v-54a76df6]:before{ background-color: #E81B16;
}
.order-container-list-item[data-v-54a76df6]:after{ content: ""; position: absolute; left: -13px; top: 5px; width: 1px; height: 100%; background-color: #e9e9e9; z-index: 1;
}
.order-container-list-item[data-v-54a76df6]:last-child:after{ display: none;
}
.order-container-list-item[data-v-54a76df6]:last-child{ padding-bottom: 0;
}
.order-status-txt[data-v-54a76df6]{ color: #E81B16;
}
.order-delivery-form[data-v-54a76df6]{ text-align: left;
}
.express-company[data-v-54a76df6]{ color: #ccc;
}
.edit-icon-box[data-v-54a76df6]{ float: right;
}
.edit-icon[data-v-54a76df6]{ margin: 0 0 2px 4px; width: 12px; height: 12px;
}
.order-change-img[data-v-54a76df6]{ display: inline-block; margin-right: 5px; margin-bottom: 5px; width: 50px; height: 50px;
}
.equipment-modal-from-checkbox[data-v-54a76df6]{ text-align: left; font-size: 12px; clear: both; margin-bottom: 5px;
}
.orderConfirm-main-image-item[data-v-54a76df6]{ float: left; width: 50px; height: 50px; -webkit-box-sizing: border-box; box-sizing: border-box; padding: 3px 3px 3px 0;
}
.orderConfirm-main-image-info[data-v-54a76df6]{ position: relative; width: 100%; height: 100%; border: 1px solid #dbdbdb; border-radius: 5px; overflow: hidden; position: relative;
}
.orderConfirm-main-image-line-left[data-v-54a76df6]{ position: absolute; left: 50%; top: 5px; bottom: 5px; width: 1px; background-color: #c2c2c2; -webkit-transform: translateX(-50%); transform: translateX(-50%);
}
.orderConfirm-main-image-line-top[data-v-54a76df6]{ position: absolute; top: 50%; left: 5px; right: 5px; height: 1px; background-color: #c2c2c2; -webkit-transform: translateY(-50%); transform: translateY(-50%);
}
.upload-input[data-v-54a76df6]{ position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0;
}
.image-full[data-v-54a76df6]{ width: 100%; height: 100%;
}
.copy[data-v-54a76df6]{ color: #E81B16; float: right;
}
